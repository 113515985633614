<template>
  <div class="gallery-container">
    <div class="gallery-grid">
      <div
        v-for="(item, index) in photos"
        :key="index"
        class="gallery-item"
        @click="openItem(item.url)"
      >
        <div class="gallery-item-inner">
          <div v-if="isPDF(item.url)" class="pdf-overlay">
            <i class="fa fa-file-pdf-o fa-3x"></i>
          </div>
          <img
            :src="getItemThumbnail(item)"
            :alt="item.name || 'Gallery Item'"
            class="img-gallery"
          />
        </div>
        <div class="gallery-item-caption" v-if="item.name">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalServices from "@/services/global";
const global = new GlobalServices();
import MODULE from "@/constants/modules";
import editMixin from "@/mixin/edit-mixin";

export default {
  name: "EquipmentPhotoGallery",

  props: {
    equipmentId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      photos: []
    };
  },

  async mounted() {
    await this.fetchPhotos();
  },

  methods: {
    openItem(url) {
      window.open(url, "_blank");
    },

    async fetchPhotos() {
      try {
        // TEMPORARY DEMO: Hard-coded array
        this.photos = [];
          const param = {
              id: this.equipmentId,
            parentActionId: MODULE.INVENTORY.ActionId,            
            actionId: MODULE.DOCUMENTS.ActionId,
          };      


          const results = await global.getDataByActionId(param, true);

         

          if (results.table)
            this.photos = results.table.data;

      } catch (error) {
       
      }
    },

    isPDF(url) {
      return url.toLowerCase().endsWith('.pdf');
    },

    getItemThumbnail(item) {
      if (this.isPDF(item.url)) {
        return 'path/to/pdf-thumbnail.jpg'; // Replace with an actual PDF thumbnail image
      }
      return item.url;
      },
    onMount () {
      this.fetchPhotos();
    }

  }
};
</script>

<style scoped>
.gallery-container {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

.gallery-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.gallery-item-inner {
  position: relative;
  padding-top: 100%; /* Square aspect ratio */
  overflow: hidden;
}

.img-gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-item-caption {
  padding: 15px;
  background-color: rgba(173, 216, 230, 0.2); /* Light blue with low opacity */
  color: #4a4a4a; /* Darker text for better contrast */
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.pdf-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
}

@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
</style>