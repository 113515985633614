
import Vue from "vue";
import QuoteServices from "@/services/quotes";
import TemplateServices from "@/services/templates";
import EditDataObject from "@/interfaces/EditDataObject";
import "vue-simple-suggest/dist/styles.css";
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";
import editMixin from "@/mixin/edit-mixin";

// Create service instances.
const quoteApi = new QuoteServices();
const global = new GlobalServices();

export default Vue.extend({
  name: "QuoteDetails",
  mixins: [editMixin],
  props: {
    dataObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Which panel is open (e.g. "summary", "description", etc.)
      activePanel: "" as string,
      // Object holding text fields (matches your JSON field names)
      quoteDetails: {
        title: "",
        subtitle: "",
        summary: "",
        description: "",
        contentSection1: "",
        contentSection2: "",
        contentSection3: "",
        requireDepositAdvanceLabel: "",
        customText: "",
        comments: ""
      },
      showErrorWarning: false,
      subTotal: 0,
      uxTemplateList: [] as any[],
      timer: null as any,
      selectedItem: {} as any,
      config: {
        toolbarButtons: [
          ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript"],
          ["fontFamily", "fontSize", "textColor", "backgroundColor"],
          ["inlineClass", "inlineStyle", "clearFormatting"]
        ],
        height: 600,
        events: {
          "froalaEditor.initialized": function() {}
        },
        key: "YNB3fJ3B8C10D6B5D2A-9rlvqgkD6zdjI-8I-7B-22fdtB1zH-9iB3B9B6D5C2C4D3H3G3H3==",
        attribution: false
      },
      // Editing is disabled if the quote is locked (determined solely by the server value).
      isDisabled: false
    };
  },
  methods: {
    // Toggle the clicked panel; only one may be open at a time.
    togglePanel(panelName: string): void {
      this.activePanel = this.activePanel === panelName ? "" : panelName;
    },
    // Debounce the post to server after 500ms.
    async updateData(dataObj: EditDataObject): Promise<void> {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        global.postData(dataObj);
      }, 500);
    },
    // Update a field in the quoteDetails object.
    updateField(propertyName: string, propertyValue: any, displayName: string): void {
      const editObj = {} as EditDataObject;
      editObj.id = this.dataObj.data.id;
      editObj.propertyName = propertyName;
      editObj.propertyValue = propertyValue;
      editObj.displayName = displayName;
      editObj.actionId = MODULE.QUOTATION.ActionId;
      editObj.referenceNumber = this.dataObj.data.qutoteNumber;
      this.updateData(editObj);
    },
    // Fetch initial data from the server and set the lock status.
    async initializeData() {
      this.uxTemplateList = await global.getUXDropDown(
        MODULE.TEMPLATES.ActionId,
        MODULE.QUOTATION.ModuleId,
        MODULE.QUOTATION.TemplateTypeId
      );
      const id = this.dataObj.data.id;
      // Note: Changed API call to use getEditDetails from global
      const quoteFromServer = await global.getEditDetails(MODULE.QUOTATION.ActionId, id);

      // Map fields from the server.
      this.quoteDetails.title = quoteFromServer.Title || "";
      this.quoteDetails.subtitle = quoteFromServer.SubTitle || "";
      this.quoteDetails.summary = quoteFromServer.Summary || "";
      this.quoteDetails.description = quoteFromServer.Description || "";
      this.quoteDetails.contentSection1 = quoteFromServer.ContentSection1 || "";
      this.quoteDetails.contentSection2 = quoteFromServer.ContentSection2 || "";
      this.quoteDetails.contentSection3 = quoteFromServer.ContentSection3 || "";
      this.quoteDetails.requireDepositAdvanceLabel = quoteFromServer.RequireDepositAdvanceLabel;
      this.quoteDetails.customText = quoteFromServer.CustomText || "";
      this.quoteDetails.comments = quoteFromServer.Comments || "";

      if (!this.quoteDetails.description) {
        this.selectedItem = this.uxTemplateList.find((item: any) => item.isDefault === true);


      }

      //console.log("quoteFromServer", quoteFromServer.IsLockedFromEditing);

      // Set isDisabled based solely on the server value.
      // Normalize the value to lowercase string and compare.
      this.isDisabled = String(quoteFromServer.IsLockedFromEditing).toLowerCase() === "true";
      // For debugging, you can log the value:
      // console.log("IsLockedFromEditing from server:", quoteFromServer.IsLockedFromEditing, "-> isDisabled:", this.isDisabled);
    }
  },
  async mounted() {
    await this.initializeData();
  }
});
