import axios from 'axios';
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";
import cacheService from '@/services/cache'; // Import our cache service

let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class UXProjectsService {

    constructor() {
        // Set the default API base URL.
        axios.defaults.baseURL = apiUrl;

        apiHeaderObj = {
            headers: {
                'x-functions-key': apiKey
            }
        }
    }

    /**
     * Retrieves the project type dropdown.
     * Uses the cacheService to cache the API response using a unique cache key.
     *
     * @returns A promise that resolves to the project type dropdown resources.
     */
    async getProjectType(): Promise<any[]> {
        // Update base URL if needed
        let config = new API_CONFIG();
        axios.defaults.baseURL = config.API_URL;

        const auth = AuthService.getInstance();
        // Get a stable query string (ensure it doesn’t include any dynamic data like timestamps)
        const queryString = auth.getQueryString();
        // Build the API URL.
        const url = 'ux/dropdown' + queryString + "&actionId=44";
        // Construct a unique cache key.
        const cacheKey = "UXDropDown_ProjectType_" + queryString;
        
        // Use the cache service to fetch data.
        const results = await cacheService.fetchWithCache(cacheKey, async () => {
         
            const response = await axios.get(url, apiHeaderObj);
            return response.data.resources;
        });
        
        return results;
    }

    /**
     * Retrieves the project status.
     * Uses the cacheService to cache the API response using a unique cache key.
     *
     * @returns A promise that resolves to the project status resources.
     */
    async getProjectStatus(): Promise<any[]> {
        const auth = AuthService.getInstance();
        const queryString = auth.getQueryString();
        const url = 'ux/projects/status' + queryString;
        const cacheKey = "UXProjectsStatus_" + queryString;

        const results = await cacheService.fetchWithCache(cacheKey, async () => {
         
            const response = await axios.get(url, apiHeaderObj);
            return response.data.resources;
        });

        return results;
    }
}

export default UXProjectsService;
