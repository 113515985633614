<template>
  <div class="px-3 h-100" style="top: -10px">
    <div v-if="view === 'form'" class="sticky-tabs h-100">
      <b-tabs content-class="mt-3">
        <b-tab title="Email" active id="email-body-tab">
          <ValidationObserver ref="formEmailBody">
            <EmailBody v-if="emailData" :templates-list="templatesList"></EmailBody>
          </ValidationObserver>
        </b-tab>
        <b-tab title="Attachments" id="email-attachments-tab">
          <EmailAttachment v-if="!loading" @loading="handleAttachmentLoading"></EmailAttachment>
        </b-tab>
        <b-tab title="Additional Contact" id="email-additional-contact-tab">
          <EmailContact v-if="!loading"></EmailContact>
        </b-tab>
        <b-tab title="Schedule" id="email-schedule-tab">
          <EmailSchedule v-if="!loading" :email-data="emailData"></EmailSchedule>
        </b-tab>
      </b-tabs>
    </div>

    <SuccessScreen v-if="view === 'success'" :amount="0" main-text="Your email was successfully sent."></SuccessScreen>

    <div v-if="view !== 'success'" class="modal-email-footer">
      <div>
        <b-button size="md" @click="onSave" :disabled="isSendButtonDisabled" class="ss-primary-bg mr-2 border-0" :id="`modal-save-button`">
          <!-- Normal State -->
          <i v-if="!isSendButtonDisabled" class="fa fa-send u-mr-xsmall"></i>
          <!-- Processing State -->
          <img v-if="isSendButtonDisabled" src="@/assets/images/loading-spinner.gif" class="loading" alt="Loading..." />
          <span>
            {{ sendButtonLabel }}
          </span>
        </b-button>

        <!-- Cancel Button -->
        <b-button size="md" class="ss-info-bg border-0" @click="onClose" :disabled="isSending" :id="`modal-cancel-button`"> <i class="fa fa-times u-mr-xsmall"></i>Close</b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalServices from "@/services/global";
  import EmailService from "@/services/email";
  import MODULE from "@/constants/modules";
  import EVENTS from "@/constants/events";
  const global = new GlobalServices();
  const email = new EmailService();
  export default {
    data() {
      return {
        loading: true,
        isSending: false,
        isGenerating: false,
        view: "form",
        EVENT_ACTION: {},
        templatesList: [],
      };
    },
    components: {
      EmailBody: () => import("@/components/email/emailbody.vue"),
      EmailAttachment: () => import("@/components/email/emailattachment.vue"),
      EmailContact: () => import("@/components/email/emailcontact.vue"),
      EmailSchedule: () => import("@/components/email/emailschedule.vue"),
      SuccessScreen: () => import("@/components/_universal/global/success-screen.vue"),
    },
    computed: {
      modalStatus() {
        return this.$store.getters.getModalStatus;
      },
      emailData() {
        return this.$store.getters.getEmailData;
      },
      isSendButtonDisabled() {
        return this.isSending || this.isGenerating;
      },
      selectedAttachments() {
    return this.$store.getters.getSelectedAttachment.filter(attachment => attachment.isSelected);
  },
      sendButtonLabel() {
        if (!this.isSending && !this.isGenerating) {
          return "Send";
        } else if (!this.isSending && this.isGenerating) {
          return "Generating Document";
        } else {
          return "Sending...";
        }
      },
    },
    methods: {
      handleAttachmentLoading(loading) {
        this.isGenerating = loading;
      },
      async init() {
        this.loading = true;
        const emailData = await global.getEmailPreset(this.modalStatus.data.id, this.modalStatus.actionId, this.modalStatus.moduleId);
        this.$store.commit("setEmailData", emailData);
        this.loading = false;
        await this.loadTemplatesList();
      },
      async loadTemplatesList() {
        const defaultEmailTemplateTypeId = 14;
        // let actionId = MODULE.TEMPLATES.ActionId;
        // if (this.modalStatus.actionId === MODULE.INVOICE.ActionId) {
        //   actionId = this.modalStatus.actionId;
        // }
        this.templatesList = await global.getUXDropDown(MODULE.TEMPLATES.ActionId, this.modalStatus.moduleId, defaultEmailTemplateTypeId);
      },
      async onSave() {
        const isValid = await this.$refs.formEmailBody.validate();
        if (isValid) {
          this.isSending = true;
          const ccEmails = this.emailData.ccEmails ? this.emailData.ccEmails.split(",") : [];
          const bccEmails = this.emailData.bccEmails ? this.emailData.bccEmails.split(",") : [];
          const ccUnique = _.uniq([...ccEmails, ...this.$store.getters.getCcData].map((email) => email.trim()));
          const bccUnique = _.uniq([...bccEmails, ...this.$store.getters.getBccData].map((email) => email.trim()));

          let payload = {
            actionId: this.modalStatus.actionId,
            referenceId: this.modalStatus.data.id,
            toEmails: this.emailData.toEmails,
            fromEmail: this.emailData.fromEmail,
            fromName: this.emailData.fromName,
            body: this.$store.getters.getContent,
            attachments: this.selectedAttachments,
            bccEmails: bccUnique.toString(),
            ccEmails: ccUnique.toString(),
            subject: this.emailData.subject,
          };
          payload = this.attachIdByModule(payload);
          
          await email.sendEmail(payload);

          this.isSending = false;
          this.view = "success";
        }
      },
      onClose() {
        // close modal
        this.$root.$emit("close-parent-modal");
      },
      attachIdByModule(payload) {
        switch (this.modalStatus.actionId) {
          case MODULE.DASHBOARD.ActionId:
            payload.projectId = this.modalStatus.data.id;
            break;
          case MODULE.CLIENTS.ActionId:
            payload.clientId = this.modalStatus.data.id;
            break;
          case MODULE.QUOTATION.ActionId:
            payload.salesId = this.modalStatus.data.id;
            break;
          case MODULE.PURCHASEORDER.ActionId:
            payload.purchaseOrderId = this.modalStatus.data.id;
            break;
          case MODULE.INVOICE.ActionId:
            payload.invoiceId = this.modalStatus.data.id;
            break;
          default:
            payload.projectId = this.modalStatus.data.id;
            break;
        }

        return payload;
      },
    },
    created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;
      this.init();
      //Subscribe to save button "Dispatch" click event on modal box
      this.$root.$on("Save" + this.EVENT_ACTION.EMAIL.New, this.onSave);
    },
    mounted() {},
  beforeDestroy() {
    
      this.$store.commit("setEmailData", []);
      this.$store.commit("setEmailCcData", []);
      this.$store.commit("setEmailBccData", []);
      //Unscribe from save button "Dispatch" click event on modal box
      this.$root.$off("Save" + this.EVENT_ACTION.EMAIL.New, this.onSave);
    },
  };
</script>
<style>
  .modal-email-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dedede;
    padding: 15px 30px;
  }

  @media (max-width: 1566px) {
    .modal-email-footer {
      position: sticky;
      bottom: 0;
      margin-left: -35px;
      margin-right: -35px;
      margin-bottom: -15px;
    }
  }
</style>
