
  import Vue from "vue";
  import { DashboardStats } from "@/interfaces/modules/dashboard/DashboardStats";
  import { DashboardFilter } from "@/interfaces/filters/DashboardFilters";

  export default Vue.extend({
    name: "DashboardSummary",
    data() {
      return {
        // Timer for reloading stats from the API.
        interval: null as number | null,
        // Dashboard filter object.
        filter: {} as DashboardFilter,
        // Timer for a separate "view all" functionality.
        timer: null as number | null,
        // Count how many times the reloadStats API call has been made.
        apiCallCount: 0,
      };
    },
    methods: {
      onViewAllClicked(statusid: number) {
        // Update filter with selected status.
        this.filter.projectStatusId = statusid;
        // Dispatch a store action to get filtered results.
        this.$store.dispatch("getFilterResults", this.filter);
        // Emit an event to stop the "filters-timer".
        this.$root.$emit("filters-timer", true);
        // Start the "view all" timer if not already running.
        if (!this.timer) this.setTimer();
      },
      statsIcon(iconName: string, fullSize: boolean) {
        return fullSize
          ? "fa ss-white-font-color u-mr-auto " + iconName
          : "fa ss-white-font-color u-mr-auto u-mt-zero " + iconName;
      },
      helfSizeBoxCss(colourScheme: string) {
        return "ss--dashboard-information__box_module u-height-100 " + colourScheme;
      },
      fullSizeBoxCss(colourScheme: string) {
        return "ss--dashboard-information__box_module u-height-100 " + colourScheme;
      },
      async reloadStats() {
        // Dispatch the action to check for new dashboard stats.
        await this.$store.dispatch("checkforNewDashboardStats");
      },
      async handleReloadStats() {
        // If the API call count has reached 30, stop the timer.
        if (this.apiCallCount >= 30) {
          clearInterval(this.interval!);
          this.interval = null;         
          return;
        }
        // Otherwise, call reloadStats and increment the counter.
        await this.reloadStats();
        this.apiCallCount++;
       
      },
      async setTimer() {
        // This timer (different from the reloadStats timer) calls getFilterResults every 5 seconds.
        this.timer = setInterval(async () => {
          this.$store.dispatch("getFilterResults", this.filter);
        }, 10000);
      },
      clearTimer() {
        clearTimeout(this.timer!);
        this.timer = null;
      },
      handlerReloadTimer(isClear: boolean) {
        if (isClear) {
          this.clearTimer();
        } else {
          this.setTimer();
        }
      },
    },
    computed: {
      resultsfullSizeBoxs() {
        let results = this.$store.getters.getDashboardStats;
        return results ? results.summary.fullSizeBoxs : [];
      },
      resultshalfSizeBoxs() {
        let results = this.$store.getters.getDashboardStats;
        return results ? results.summary.halfSizeBoxs : [];
      },
    },
    mounted() {
      // Set up a timer to call handleReloadStats every 20 seconds.
      this.interval = setInterval(this.handleReloadStats, 20000);
      // Listen for events to pause/resume the filters timer.
      this.$root.$on("summary-timer", this.handlerReloadTimer);
    },
    beforeDestroy() {
      // Clean up: clear the reloadStats timer.
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      // Unsubscribe from the "summary-timer" event.
      this.$root.$off("summary-timer", this.handlerReloadTimer);
      // Optionally, clear the "view all" timer.
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    async created() {
      // Initialize the dashboard.
      this.$store.dispatch("initializeDashboard");
      // Trigger an immediate check for new dashboard stats.
      this.$store.dispatch("checkforNewDashboardStats", true);
    },
  });
