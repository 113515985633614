import axios, {AxiosResponse} from "axios";
import EditDataObject from "@/interfaces/EditDataObject";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import IModuleFilter from "@/interfaces/filters/ModuleFilters";
import {IResults} from "@/interfaces/IResults";
import UtilityString from "@/utilities/strings";
import {isDev} from "@/config/prototypes";
import hash from "object-hash";
// import moment from "moment";
let config = new API_CONFIG();
let apiUrl = config.API_URL;
let mockApiUrl = config.MOCK_API_URL;
let apiKey = config.API_KEY;
let apiManagementKey = config.VNEXT_MANAGEMENT_API_KEY;
let apiHeaderObj;
let new_vNext_Legacy_API = "https://api-snapsuite-legacy.azurewebsites.net/api/";

import {IUXList, IListData, ITableHeader, ITable, ITableHoverMenu, ITableAction, IEditAction, IRoute} from "@/interfaces/IUXList";
import {ITabParam, ITabSearchParam} from "@/interfaces/UXSidePanel";
import MODULE from "@/constants/modules";
import { params } from "vee-validate/dist/types/rules/alpha";
import cacheService from "@/services/cache";

class GlobalServices {

// Cache object for storing table headers.
  // The key is the unique URL (excluding correlationId) and the value is the header array.
  private headerCache: { [key: string]: ITableHeader[] } = {};

  private static UX_DROPDOWN_CACHE_KEY = "UXDropdownCache";
  private static UX_DROPDOWN_CACHE_EXPIRATION = 3 * 60 * 60 * 1000; // 3 hours in milliseconds


  config = new API_CONFIG();
  auth = AuthService.getInstance();
  utility = new UtilityString();


  constructor() {
    axios.defaults.baseURL = apiUrl;

    apiHeaderObj = {
      headers: {
        "x-functions-key": apiKey,
        "x-snapsuite-key": apiManagementKey,
      },
    };
  }

  getActions(): ITableAction {
    let action = new Object() as ITableAction;

    //Not implemented for now
    action.isEnabled = false;

    return action;
  }

  //Generically Loads Any List By Action ID, filtering by Id
  async getDataByActionId(param: ITabParam, isTabList: Boolean): Promise<IUXList> {
    let list: IUXList = new Object() as IUXList;

    // //Ignore Tab list
    if (isTabList) {
      //Table Header Properties
      list.table = new Object() as ITable;
      if (param.actionId === MODULE.TIMETRACKING.ActionId || param.actionId === MODULE.PAYMENT.ActionId) {
        list.table.header = await this.getTableTabHeadersByActionId(param.actionId);
      } else {
        list.table.header = await this.getTableHeadersByActionId(param.actionId);
      }
      // //Data
      list.table.data = await this.getTabTableleDataByActionId(param);
    } else {
      // //Data
      list.table.data = await this.getTableDataByActionId(param);
    }

    // //More Options - Action Menu
    // list.table.actions = new Object() as ITableAction;
    // list.table.actions = this.getActions();

    // //Filters
    // list.filter = await this.getFilters();

    //Edit Action - i.e. when user clicks Edit, what should happen.
    // list.editAction = new Object() as IEditAction;
    // list.editAction.route = new Object() as IRoute;
    // list.editAction.route = {
    //   name: "client-details",
    //   path: "/clients/details",
    //   param: {
    //     id: 0, //Id will be set once the record has been clicked
    //   },
    // };
    return list;
  }
  //Generically Loads Any List By Action ID, filtering by Id
  async getDocumentDataByActionId(param: ITabParam): Promise<any> {
    const results = (await this.getTabTableleDataByActionId(param)) as any;
    return results;
  }

  async getTableDataOnly(param: ITabParam) {
    return await this.getTabTableleDataByActionId(param);
  }

  //Generically Loads Any List By Action ID, filtering by Id
  async getDataReportsList(param: ITabParam): Promise<IUXList> {
    let list: IUXList = new Object() as IUXList;

    //Table Header Properties
    list.table = new Object() as ITable;
    list.table.header = await this.getTableHeadersByActionId(param.actionId);
    // //Data
    list.table.data = await this.getTableReportDataByActionId(param);
    list.table.stats = await this.getStatsReportDataByActionId(param);
    return list;
  }

  // this function is specific for tabs
  async getTableReportDataByActionId(param: ITabParam): Promise<IListData> {
    let results;

    let paramObj = Object.assign(param, this.auth.getAuthObject());

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let queryString = this.utility.parseQueryString(paramObj);

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("global/reports/list" + "?" + queryString + correlationId, apiHeaderObj);

    return results.data.resources || [];
  }

  async getStatsReportDataByActionId(param: ITabParam): Promise<IListData> {
    let results;

    let paramObj = Object.assign(param, this.auth.getAuthObject());

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let queryString = this.utility.parseQueryString(paramObj);

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("global/reports/info" + "?" + queryString + correlationId, apiHeaderObj);

    return results.data.resources;
  }

  // this function is specific for tabs
  async getTabTableleDataByActionId(param: ITabParam): Promise<IListData> {
    let results;
    let paramObj = Object.assign(param, this.auth.getAuthObject());

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let queryString = this.utility.parseQueryString(paramObj);

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("global/list/tab" + "?" + queryString + correlationId, apiHeaderObj);
    return results.data.resources;
  }

  // this function is specific for tabs
  async getSearchTabTableDataByActionId(param: ITabSearchParam): Promise<IListData> {
    let results;

    let paramObj = Object.assign(param, this.auth.getAuthObject());

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let queryString = this.utility.parseQueryString(paramObj);

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("global/list/tab/search" + "?" + queryString + correlationId, apiHeaderObj);

    return results.data.resources;
  }

  // this function is specific for generic listing
  async getTableDataByActionId(param: ITabParam): Promise<IListData> {
    let results;

    let paramObj = Object.assign(param, this.auth.getAuthObject());

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let queryString = this.utility.parseQueryString(paramObj);

    axios.defaults.baseURL = this.config.API_URL;

    results = await axios.get("global/list" + "?" + queryString + correlationId, apiHeaderObj);

    return results.data.resources;
  }

/**
   * Retrieves table headers for the given actionId.
   * Caches the result by a unique key built from the API endpoint and query string.
   *
   * @param actionId The action id used to fetch table headers.
   * @returns A promise resolving to an array of table headers.
   */
  async getTableHeadersByActionId(actionId: Number): Promise<ITableHeader[]> {
    // Construct the parameter string without the correlation ID.
    let paramString: string = this.auth.getQueryString() + "&actionId=" + actionId + "&id=0";
    // Build the cache key from the endpoint and parameter string.
    let cacheKey: string = "global/list/header" + paramString;

    // Return cached headers if available.
    if (this.headerCache[cacheKey] !== undefined) {
      return this.headerCache[cacheKey];
    }

    // Set the base URL.
    axios.defaults.baseURL = this.config.API_URL;
    // Append a unique correlation ID.
    let correlationId: string = "&correlationId=" + this.utility.generateGUID();
    let results = await axios.get("global/list/header" + paramString + correlationId, apiHeaderObj);
    
    // Cache the result for subsequent calls.
    this.headerCache[cacheKey] = results.data.resources;
    
    return results.data.resources;
  }

  /**
   * Retrieves table headers for the given actionId with an additional parameter.
   * Caches the result by a unique key built from the API endpoint and query string.
   *
   * @param actionId The action id used to fetch table headers.
   * @param param An object that may contain additional parameters (e.g., only).
   * @returns A promise resolving to an array of table headers.
   */
  async getTableHeadersByActionIdAndAdditionalParam(actionId: Number, param: any): Promise<ITableHeader[]> {
    // Construct the base parameter string.
    let paramString: string = this.auth.getQueryString() + "&actionId=" + actionId + "&id=0";
    if (param.only) {
      paramString += "&only=" + param.only;
    }
    // Build the cache key from the endpoint and parameter string.
    let cacheKey: string = "global/list/header" + paramString;

    // Return cached headers if available.
    if (this.headerCache[cacheKey] !== undefined) {
      return this.headerCache[cacheKey];
    }

    axios.defaults.baseURL = this.config.API_URL;
    // Append a unique correlation ID.
    let correlationId: string = "&correlationId=" + this.utility.generateGUID();
    let results = await axios.get("global/list/header" + paramString + correlationId, apiHeaderObj);
    
    // Cache the result.
    this.headerCache[cacheKey] = results.data.resources;
    
    return results.data.resources;
  }

  /**
   * Retrieves table headers for the given actionId for tab headers.
   * Caches the result by a unique key built from the API endpoint and query string.
   *
   * @param actionId The action id used to fetch table tab headers.
   * @returns A promise resolving to an array of table headers.
   */
  async getTableTabHeadersByActionId(actionId: Number): Promise<ITableHeader[]> {
    // Construct the parameter string.
    let paramString: string = this.auth.getQueryString() + "&actionId=" + actionId + "&id=0";
    // Build the cache key (different endpoint for tab headers).
    let cacheKey: string = "global/list/tab/header" + paramString;

    // Return cached headers if available.
    if (this.headerCache[cacheKey] !== undefined) {
      return this.headerCache[cacheKey];
    }

    axios.defaults.baseURL = this.config.API_URL;
    // Append a unique correlation ID.
    let correlationId: string = "&correlationId=" + this.utility.generateGUID();
    let results = await axios.get("global/list/tab/header" + paramString + correlationId, apiHeaderObj);
    
    // Cache the result.
    this.headerCache[cacheKey] = results.data.resources;
    
    return results.data.resources;
  }


  //Tigger a background job based on the actionId
  async backgroundjob(actionid: Number) {
    let results;

    let paramString = this.auth.getQueryString() + "&actionId=" + actionid;

    

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    results = await axios.get("https://api.getsnapsuite.com/trigger/backgroundjob/invoice" + paramString + correlationId, apiHeaderObj);

    axios.defaults.baseURL = this.config.API_URL;

    return results.data.resources;    
  }

   migrationAPI (actionId) {
        //MIGRATION RULE - Conditionally set base url based on the actionId to support the new API
      // Determine the appropriate base URL
      if (actionId === 85
        || actionId === 24 //Purchase order preset
      ) {
        return new_vNext_Legacy_API; // Replace default_API_URL with your default
      } 
        return this.config.API_URL;
     
  }

  //Global Generic update method method, updates an individual column value
  async postData(editObj: EditDataObject) {
    let statusResult;
    let errorMsg;

    //Ensure we're not posting invalid action/table
    if (editObj.actionId > 0) {
      editObj = Object.assign(editObj, this.auth.getAuthObject());
      editObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      editObj.hash = hash(editObj);
      editObj.correlationId = this.utility.generateGUID();

      let baseURL = this.config.API_URL;

      //MIGRATION RULE - Conditionally set base url based on the actionId to support the new API
      baseURL = this.migrationAPI(editObj.actionId);
       
      try {
        statusResult = await axios.put("global/update", editObj, {
          ...apiHeaderObj,
          baseURL, // Set the baseURL for this request
        });
      } catch (error) {
        errorMsg = error;
      }
    }
    
    if (statusResult) {
      return statusResult.data;
    } else {
      return {
        success: false,
        message: "error updating record.",
      };
    }
  }

  //Global - get details data based on actionId and id
  async getEditDetails(actionId: number, id: number) {
    let results;

    let paramString = this.auth.getQueryString() + "&actionId=" + actionId + "&id=" + id;

    axios.defaults.baseURL = this.config.API_URL;

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    results = await axios.get("/global/edit" + paramString + correlationId, apiHeaderObj);

    if (results) {
      return results.data.resources;
    } else {
      return {
        success: false,
        message: "error updating record.",
      };
    }
  }

  //Global - get details data based on actionId and id
  async getEditStats(actionId: number, id: number) {
    let results;

    let paramString = this.auth.getQueryString() + "&actionId=" + actionId + "&id=" + id;

    axios.defaults.baseURL = this.config.API_URL;

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    results = await axios.get("/global/edit/stats" + paramString + correlationId, apiHeaderObj);

    if (results) {
      return results.data.resources;
    } else {
      return {
        success: false,
        message: "error updating record.",
      };
    }
  }
  //Global - get details data based on actionId and id
  async getClientLookup(query) {
    axios.defaults.baseURL = this.config.API_URL;
    let results;

    let paramString = this.auth.getQueryString();
    if (query.q) {
      paramString += `&q=${encodeURIComponent(query.q)}`;
    }
    if (query.email) {
      paramString += `&email=${query.email}`;
    }
    if (query.address) {
      paramString += `&address=${query.address}`;
    }

    paramString += `&actionId=${query.actionId}`;
    const correlationId = "&correlationId=" + this.utility.generateGUID();

    results = await axios.get("/global/clients/lookup" + paramString + correlationId, apiHeaderObj);

    if (results) {
      return results.data.resources;
    } else {
      return {
        success: false,
        message: "error getting clients data",
      };
    }
  }

  //Global - get details data based on actionId and id
  async getEditModuleDetails(id: number) {
    let results;

    let paramString = this.auth.getQueryString() + "&actionId=" + id + "&id=" + id;

    axios.defaults.baseURL = this.config.API_URL;

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    results = await axios.get("/application/table/metadata" + paramString + correlationId, apiHeaderObj);

    return results.data.resources;
  }

  async globalRecalculate(activityObj) {
    let statusResult;
    let errorMsg;

    if (activityObj) {
      activityObj = Object.assign(activityObj, this.auth.getAuthObject());

      //Hash object
      activityObj.hash = hash(activityObj);

      activityObj.correlationId = this.utility.generateGUID();

      statusResult = await axios.post("global/recalculate", activityObj, apiHeaderObj).catch(function(error) {
        errorMsg = error;
      });

      return statusResult.data.resources;
    }
  }

  async globalCalculations(actionId: number, id: number, guid: string) {
    let results;

    let paramString = this.auth.getQueryString() + "&actionId=" + actionId + "&id=" + id + "&guid=" + guid;

    axios.defaults.baseURL = this.config.API_URL;

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    results = await axios.get("/global/calculation" + paramString + correlationId, apiHeaderObj);

    if (results) {
      return results.data.resources;
    } else {
      return {
        success: false,
        message: "error fetching calculation record.",
      };
    }
  }

  //Global Generic update method method
  async createNewActivity(actionId, newActivityObj) {
    let statusResult;

    if (newActivityObj) {
      newActivityObj = Object.assign(newActivityObj, this.auth.getAuthObject());

      //Append Action Id to object data
      newActivityObj = Object.assign(newActivityObj, {actionId});
      //Hash object
      newActivityObj.hash = hash(newActivityObj);

      newActivityObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      newActivityObj.correlationId = this.utility.generateGUID();

      statusResult = await axios.post("global/activity", newActivityObj, apiHeaderObj);
      return statusResult.data;
    }
  }

  //Global Generic update method method
  async createNewRecord(actionId, newObj) {
    let statusResult;

    newObj = Object.assign(newObj, this.auth.getAuthObject());

    //Append Action Id to object data
    newObj = Object.assign(newObj, {actionId: actionId});

    //Hash object
    newObj.hash = hash(newObj);

    newObj.correlationId = this.utility.generateGUID();

    statusResult = await axios.put("global/new", newObj, apiHeaderObj).catch(function(error) {});

    return statusResult.data;
  }

  //Global Helper method to look up the default value an return the object.
  setDropDownDefault(data) {
    let results = {};

    if (data) {
      //If there are multiple records, then look for the default flag if set
      if (data.length >= 1) {
        results = data.find((list) => list.isDefault == true);
      }

      if (!results) {
        results = data[0];
      }
    }
    return results;
  }

  async getSimpleUXDropDown(actionId, parentActionId?, id?) {
    let queryString = this.auth.getQueryString() + "&actionId=" + actionId;

    if (parentActionId != undefined) queryString += "&parentActionId=" + parentActionId;

    if (id) {
      queryString += "&id=" + id;
    }
    //Generic API for returning UX DropDown values
    return await this.callAPIUXDropDown(queryString);
  }

  async getUXDropDown(actionId, moduleId?, typeId?, moduleFilter: IModuleFilter = null, id?, parentClientId?, show = {disabled: false, deleted: false}, q = null) {
    let queryString = this.auth.getQueryString() + "&actionId=" + actionId;

    if (moduleId != undefined) queryString += "&moduleId=" + moduleId;

    if (typeId != undefined) queryString += "&typeId=" + typeId;

    if (moduleFilter) {
      if (moduleFilter.clientId) queryString += "&cid=" + moduleFilter.clientId;
    }

    if (id) {
      queryString += "&id=" + id;
    }

    if (parentClientId) {
      queryString += "&parentClientId=" + parentClientId;
    }

    if (show.disabled) {
      queryString += "&showDisabled=" + show.disabled;
    }

    if (show.deleted) {
      queryString += "&showDeleted=" + show.deleted;
    }

    if (q && q.length > 0) {
      queryString += "&q=" + q;
    }

    //Generic API for returning UX DropDown values
    return await this.callAPIUXDropDown(queryString);
  }

  async getUXDropDownMetadata(actionId, moduleId?, typeId?, moduleFilter: IModuleFilter = null, id?, parentClientId?) {
    let queryString = this.auth.getQueryString() + "&actionId=" + actionId;

    if (moduleId != undefined) queryString += "&moduleId=" + moduleId;

    if (typeId != undefined) queryString += "&typeId=" + typeId;

    if (moduleFilter) {
      if (moduleFilter.clientId) queryString += "&cid=" + moduleFilter.clientId;
    }

    if (id) {
      queryString += "&id=" + id;
    }

    if (parentClientId) {
      queryString += "&parentClientId=" + parentClientId;
    }

    //Generic API for returning UX DropDown values
    return await this.callAPIUXDropDownMetadata(queryString);
  }

  // OLD
  //Look up table via column name for dropdown
  async getUXDropDownByColumnName(actionId, columnName) {
    let queryString = this.auth.getQueryString() + "&actionId=" + actionId;

    //The name of the column to lookup/retun a list of values for
    if (columnName) queryString += "&p1=" + columnName;

    //Generic API for returning UX DropDown values
    return await this.callAPIUXDropDown(queryString);
  }



/**
 * Generic API for returning UX DropDown values using localStorage caching.
 *
 * This method builds a unique cache key based on the query string and uses
 * the global $cache.fetchWithCache method from your localCache.js plugin.
 *
 * @param queryString A query string built from authentication and parameters.
 * @returns A promise resolving to the dropdown resources array.
 */
async callAPIUXDropDown(queryString: string): Promise<any[]> {
    // Log the input query string.
  

    // Build a unique cache key using the queryString.
    const cacheKey: string = "UXDropDown_" + queryString;
    

    // Define the API call as an async function.
    const apiCall = async (): Promise<any[]> => {
        // Append a unique correlation ID to bypass server caching.
        const correlationId: string = "&correlationId=" + this.utility.generateGUID();
       
        try {
        
            const dataResults = await axios.get("ux/dropdown" + queryString + correlationId, apiHeaderObj);
            
            return dataResults.data.resources;
        } catch (err) {
          
            return [];
        }
    };

    // Use our cache service to fetch with caching.
    return await cacheService.fetchWithCache(cacheKey, apiCall);
}

/**
 * Generic API for returning UX DropDown metadata using localStorage caching.
 *
 * Similar to callAPIUXDropDown, this method uses a unique cache key to store and retrieve
 * the metadata response from localStorage via the $cache.fetchWithCache plugin method.
 *
 * @param queryString A query string built from authentication and parameters.
 * @returns A promise resolving to the dropdown metadata resources.
 */
async callAPIUXDropDownMetadata(queryString: string): Promise<any> {
    // Log the input query string.
  

    // Build a unique cache key for metadata.
    const cacheKey: string = "UXDropDownMetadata_" + queryString;


    const apiCall = async (): Promise<any> => {
        const correlationId: string = "&correlationId=" + this.utility.generateGUID();
       
        try {
            const dataResults = await axios.get("ux/dropdown/metadata" + queryString + correlationId, apiHeaderObj);
         
            return dataResults.data.resources;
        } catch (err) {
           
            return null;
        }
    };

    // Use localStorage caching if available.
   return await cacheService.fetchWithCache(cacheKey, apiCall);

}
  
  
  

  getFilter(filterObj) {
    if (filterObj) {
      filterObj.id = 0;
      filterObj.maximumRows = 100;
    } else {
      filterObj = {
        id: 0,
        maximumRows: 100,
      };
    }

    let auth = AuthService.getInstance();
    return Object.assign(filterObj, auth.getAuthObject());
  }

  // INVOICE
  async recalculateInvoice(invoiceId) {
    const correlationId = "&correlationId=" + this.utility.generateGUID();
    const paramString = this.auth.getQueryString() + `&id=${invoiceId}${correlationId}`;
    const results = await axios.get(`/invoice/recalculate${paramString}`, apiHeaderObj);

    return results.data.resources;
  }

  async getEmailPreset(id, actionId, moduleId) {
    let queryString = this.auth.getQueryString() + "&id=" + id + "&actionId=" + actionId + "&moduleId=" + moduleId;
    // const customizedConfig = {
    //   ...apiHeaderObj,
    //   baseURL: isDev() ? "https://16ab22cd-3ceb-4f5a-ba8e-5a6bafe554ee.mock.pstmn.io/" : apiUrl,
    // };

    let dataResults = await axios.get("template/engine/email/preset" + queryString, apiHeaderObj);
    return dataResults.data.resources;
  }

  async getSnippets(id, actionId) {
    let queryString = this.auth.getQueryString() + "&id=" + id + "&actionId=" + actionId;
    const customizedConfig = {
      ...apiHeaderObj,
      baseURL: isDev() ? "https://16ab22cd-3ceb-4f5a-ba8e-5a6bafe554ee.mock.pstmn.io/" : apiUrl,
    };

    let dataResults = await axios.get("engine/email/snippets" + queryString, customizedConfig);
    return dataResults.data.resources;
  }

  async magicWand(activityObj) {
    let statusResult;
    let errorMsg;
    apiHeaderObj = Object.assign(apiHeaderObj, {"x-snapsuite-key": "1d02c5307a67466abe16aa7688806e68"});
    const customizedConfig = {
      ...apiHeaderObj,
      baseURL: isDev() ? "https://16ab22cd-3ceb-4f5a-ba8e-5a6bafe554ee.mock.pstmn.io/" : apiUrl,
    };
    activityObj = Object.assign(activityObj, this.auth.getAuthObject());

    //Hash object
    activityObj.hash = hash(activityObj);

    activityObj.correlationId = this.utility.generateGUID();

    statusResult = await axios.post("engine/magic/wand", activityObj, customizedConfig).catch(function(error) {
      errorMsg = error;
    });

    return statusResult.data;
  }

  async getEmailTemplatePreset(id, templateId, actionId, moduleId) {
    let queryString = this.auth.getQueryString() + "&id=" + id + "&actionId=" + actionId + "&moduleId=" + moduleId + "&templateId=" + templateId;

        //MIGRATION RULE - Conditionally set base url based on the actionId to support the new API
    let baseURL = this.migrationAPI(actionId);
  

    let dataResults = await axios.get(baseURL+"template/engine/lookup" + queryString, apiHeaderObj);
    return dataResults.data.resources;
  }

  async getChartsData(params) {
    let results;

    let paramObj = Object.assign(params, this.auth.getAuthObject());

    let correlationId = "&correlationId=" + this.utility.generateGUID();

    let queryString = this.utility.parseQueryString(paramObj);

    results = await axios.get("global/charts?" + queryString + correlationId, apiHeaderObj);

    return results.data.resources;
  }

  async uploadGenericFile(activityObj) {
    let statusResult;
    let errorMsg;

    if (activityObj) {
      activityObj = Object.assign(activityObj, this.auth.getAuthObject());

      //Hash object
      activityObj.hash = hash(activityObj);

      activityObj.correlationId = this.utility.generateGUID();

      statusResult = await axios.post("documents/simple/upload", activityObj, apiHeaderObj).catch(function(error) {
        errorMsg = error;
      });

      return statusResult.data;
    }
  }
}

export default GlobalServices;
