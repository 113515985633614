<template>
  <div class="pdf-container">    
    <div v-if="selectedPDF && selectedPDF.url" class="modal-action-bar_pdf">
          
   
  
      <div  class="d-flex align-items-center px-3 py-2">
        <div class="mr-auto">
          <a :href="selectedPDF.url" target="_blank" size="md" class="btn mr-3 c-btn-outlined-info"> <i class="fa  fa-file-pdf-o mr-2"></i> Open PDF in Browser </a>
        </div>
        <div class="ml-auto">
          <a :href="selectedPDF.url" @click.prevent="downloadPDF" size="md" class="btn mr-3 c-btn-outlined-info"> <i class="fa  fa-file-pdf-o mr-2"></i> Download </a>
          <a target="_blank" @click="openInNewTab" size="md" class="btn c-btn-outlined-info">
            <i class="fa fa-window-restore u-mr-xsmall"></i>
            Open in New Tab
          </a>
          <a target="_blank" :href="selectedPDF.url" @click.prevent="onOpenInWord" size="md" class="btn c-btn-outlined-info mx-3">
            <i class="fa fa-file-word-o u-mr-xsmall"></i>
            Open in MS-Word
          </a>
           <a v-if="isAircoolSystem" @click.prevent="viewPlaceholders" size="md" class="btn c-btn-outlined-info mr-2">
            <i class="fa fa-window-restore u-mr-xsmall"></i>
            Placeholders
          </a>
          <b-button @click="onPrint" size="md" class="ss-info-bg border-0">
            <i class="fa fa-print u-mr-xsmall"></i>
            Print
          </b-button>
        </div>
      </div>
    </div>
    <LoadingScreen v-if="loading" />
       <div v-if="showPlaceholders || placeholderLoading" style="height: 600px; overflow-y: auto;">
        <div v-if="placeholderLoading" class="text-center py-5">
          <LoadingScreen  />
        </div>
        <vue-json-to-table v-else :data="placeholderData"></vue-json-to-table>
      </div>
    <vue-pdf-app 
      v-if="!loading && !showPlaceholders && pdfURL" 
      theme="dark" 
      :config="config" 
      :pdf="pdfURL" 
      @open="openHandler" 
      ref="vue-pdf" 
      style="height: 100vh">
    </vue-pdf-app>
  </div>
</template>

<script>
  import VuePdfApp from "vue-pdf-app";
  import LoadingScreen from "@/components/_universal/global/loading-screen.vue";
  import "vue-pdf-app/dist/icons/main.css";
  import print from "print-js";
  import ApplicationService from "@/services/application";
  import axios from "axios";

  const app = new ApplicationService();

  const getSidebar = () => ({
    viewThumbnail: true,
    viewOutline: true,
    viewAttachments: true,
  });

  export default {
    name: "ViewPdf",
    data() {
      return {
        modalStatus: {},
        config: {
          toolbar: false,
          sidebar: getSidebar(),
        },
        aircoolTenantId: "b604d9ec-2ccf-4dd2-92c5-9824fac11428",
        isAircoolSystem: false,
        loading: false,
        placeholderLoading: false,
        showPlaceholders: false,
        placeholderData: {},
        selectedPDF: null,
        document: null,
        timer: null,
        retryCount: 0,
        MAX_RETRIES: 15
      };
    },
    components: {
      VuePdfApp,
      LoadingScreen,
    },
    mounted() {
      this.initializeData();
    },
    beforeDestroy() {
      this.clearTimer();
    },
    computed: {
      pdfURL() {
        return this.selectedPDF && this.selectedPDF.url ? this.selectedPDF.url : null;
      },
    },
    methods: {
      openHandler(pdf) {
        let outerContainerHtml = document.getElementById("outerContainer");
        outerContainerHtml.classList.add("sidebarOpen");
        if (pdf && !pdf.pdfSidebar.isOpen) {
          this.config.sidebar = getSidebar();
          pdf.pdfSidebar.isOpen = true;
        }
      },
      viewPlaceholders() {
        this.placeholderLoading = true;
        this.showPlaceholders = true;  // Set this to true immediately

        // Define module ID mappings based on keywords
        const moduleMappings = {
          quote: 6,
          project: 2,
          invoice: 26,
          purchaseorder: 7,
        };

        let moduleId = 6; // Default module ID

        // Check if `selectedPDF.url` contains any keyword and assign the corresponding module ID
        for (const key in moduleMappings) {
          if (this.selectedPDF.url.toLowerCase().includes(key)) {
            moduleId = moduleMappings[key];
            break;
          }
        }

        // Dynamically compose print placeholder URL
        let url = `/print/document/generator?moduleid=${moduleId}&id=${this.selectedPDF.linkID}`;
        url += `&userid=1&u=f17c2640-933b-4e01-aa0b-2fbe518f71f9&t=${this.aircoolTenantId}`;

        // Headers for API request
        const headers = {
          "x-functions-key": "LaVFhUo4k2uFcWsn8izsOiBWBLR4da35UpEZI3RWfxIVxx7rlIhCRg=="
        };

        // Make API GET request
        axios.get(url, { headers })
          .then((response) => {
            this.placeholderData = response.data.resources;
          })
          .catch((error) => {
            //console.error("Error fetching placeholders:", error);
            this.showPlaceholders = false; // Reset on error
          })
          .finally(() => {
            this.placeholderLoading = false;
          });
      },
      async downloadPDF() {
        const response = await axios.get(this.selectedPDF.url, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([response.data], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.selectedPDF.documentName;
        link.click();
      },
      onOpenInWord() {
        if (!this.selectedPDF.wordURL) return;
        var lnk = "ms-word:ofe|u|" + this.selectedPDF.wordURL + "|a|Web";
        window.location.href = lnk;
      },
      openInNewTab() {
        if (!this.selectedPDF.url) return;
        let routeData = this.$router.resolve({name: "pdf-preview", query: {url: this.selectedPDF.url, wordURL: this.selectedPDF.wordURL, name: this.selectedPDF.documentName}});
        window.open(routeData.href, "_blank");
      },
      initializeData() {
        this.loading = true;
        this.modalStatus = this.$store.getters.getModalStatus;
        this.selectedPDF = this.modalStatus.data;

        if (this.selectedPDF.params && this.selectedPDF.type === "preview") {
          //Delay for 3 before loading pdf, this will give the PDF generator time to finish
          //This will help  prevent the PDF from loading before the PDF generator is finished
          const sleep = (time) => new Promise((res) => setTimeout(res, time, "done sleeping"));
          sleep(2000).then(() => this.getPreviewDocument());
        } else {
          this.loading = false;
        }
        
        // Check if it's an Aircool system
        if (this.selectedPDF.url && this.selectedPDF.url.includes(this.aircoolTenantId)) {
          this.isAircoolSystem = true;
        }
      },
      async getPreviewDocument() {
        try {
          this.document = await app.print(this.selectedPDF.params.moduleId, this.selectedPDF.params.id);

          if (this.document) {
            const updatedData = {
              ...this.modalStatus,
              data: this.document,
            };

            this.$store.commit("setModalStatus", updatedData);
            this.modalStatus = this.$store.getters.getModalStatus;
            this.selectedPDF = this.modalStatus.data;

            //Append a unit URL to the pdf to prevent browser caching
            this.selectedPDF.url = this.selectedPDF.url + "?v=" + new Date().getTime();

            if(this.selectedPDF.url.includes(this.aircoolTenantId)) {
              this.isAircoolSystem = true;
            }

            this.loading = false;
            this.clearTimer();
            this.retryCount = 0;
          } else if (this.retryCount < this.MAX_RETRIES) {
            this.retryCount++;
            this.timer = setTimeout(() => {
              this.getPreviewDocument();
            }, 2000);
          } else {
            this.loading = false;
            //console.error('Failed to load document after maximum retries');
          }
        } catch (e) {
          if (this.retryCount < this.MAX_RETRIES) {
            this.retryCount++;
            this.timer = setTimeout(() => {
              this.getPreviewDocument();
            }, 2000);
          } else {
            this.loading = false;
            //console.error('Failed to load document after maximum retries:', e);
          }
        }
      },
      clearTimer() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
      },
      onPrint() {
        if (!this.selectedPDF.url) return;
        print(this.selectedPDF.url);
      },
    },
  };
</script>
<style>
.key {
  min-width: 450px !important;
  white-space: nowrap;
  font-weight: bold;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>