
  import Vue from "vue";

  import MODULE from "@/constants/modules";
  import GlobalServices from "@/services/global";
  import ActivityServices from "@/services/activity";
  import Router from "@/router";
  import EVENTS from "@/constants/events";

  const global = new GlobalServices();
  const api = new ActivityServices();

  export default {
    props: {
      dataObj: Object,
    },
    data() {
      return {
        EVENT_ACTION: {},
        intervalApi: null,
        modalStatus: {
          hideShowModal: false,
          modalName: "",
          isSaveButtonHidden: false,
          modalTitle: "",
          data: {},
        },
        isSuccesful: false,
        isError: false,
        activities: {},
        emailActivities: {},
      };
    },
    methods: {
      showModal(paramModalName, modalTitle, id) {
        this.modalStatus.hideShowModal = true;
        this.modalStatus.modalName = paramModalName;
        this.modalStatus.data = {
          id: id,
        };
        this.modalStatus.modalTitle = modalTitle;
        this.modalStatus.isSaveButtonHidden = true;
        this.modalStatus.modalSaveButtonTitle = "";
        this.$store.dispatch("toggleModalStatus", this.modalStatus);
      },
      async onSave(data) {},

      async initializeData() {
        let id = this.dataObj.data.intProjectID ? this.dataObj.data.intProjectID : this.dataObj.param.id;
        if (id > 0) {
          const moduleId = this.dataObj.param.moduleId ? this.dataObj.param.moduleId : MODULE.PROJECTS.ModuleId;
          const parentId = this.dataObj.param.parentId ? this.dataObj.param.parentId : null;
          let additionalParam = {} as any;

          if (this.dataObj.param.parentId) {
            additionalParam.parentId = parentId;
          }

          // we added this only because its needed in employee details page
          // https://github.com/snapsuiteio/jupiter/issues/1019
          if (this.$route.name === "employees-details") {
            additionalParam.EmployeeID = this.dataObj.param.id;
          }
          this.activities = await api.getActivity(id, moduleId, additionalParam);
          this.emailActivities = await api.getEmailActivity(id, moduleId, additionalParam);
        }
      },
      classColour(activityTypeName) {
        if (activityTypeName == "Emailed") {
          return {"c-task--info": true};
        } else if (activityTypeName == "Notes") {
          return {"c-task--success": true};
        } else if (activityTypeName == "Call" || activityTypeName == "System") {
          return {"c-task--fancy": true};
        } else if (activityTypeName == "Updated") {
          return {"c-task--warning": true};
        } else if (activityTypeName == "Schedule") {
          return {"c-task--success": true};
        } else if (activityTypeName == "Dispatch") {
          return {"c-task--black": true};
        } else if (activityTypeName == "Status") {
          return {"c-task--status": true};
        } else {
          return {"c-task--warning": true};
        }
      },
    },

    async created() {
      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      await this.initializeData();

      this.intervalApi = setInterval(async () => {
        await this.initializeData();
      }, 20000);

      //Subscribe to save button "Dispatch" click event on modal box
      //this.$root.$on("Save" + this.EVENT_ACTION.ACTIVITY.New, this.onSave);
    },
    beforeDestroy() {
      clearInterval(this.intervalApi);
      //Unscribe from save button "Dispatch" click event on modal box
      //this.$root.$off("Save" + this.EVENT_ACTION.ACTIVITY.New, this.onSave);
    },
  };
