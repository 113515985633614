<template>
  <div class="col-md-12">
    <!-- File Uploader Section -->
    <div class="file-uploader">
      <!-- Render uploader only if a document type is selected and modalStatus data is valid -->
      <div v-if="isUploaderVisible">
        <uploader
          :moduleId="moduleId"
          :actionId="actionId"
          @uploadSuccess="onUploadAttachment"
          :id="inventoryId"
          :document-type-id="documentTypePhotosId"
          :is-file-share="isFileShare"
        />
      </div>
    </div>
  </div>
</template>

<script>
import uploader from "@/components/_universal/uploader/filepond.vue";
import GlobalServices from "@/services/global";
import MODULE from "@/constants/modules";

// Create an instance of GlobalServices to fetch data
const globalServices = new GlobalServices();

export default {
  name: "DocumentModal",
  props: {
    /// <summary>
    /// The inventory ID to be passed into the uploader
    /// </summary>
    inventoryId: {
      type: Number,
      required: true // or false if optional
    }
  },
  components: {
    uploader,
  },
  data() {
    return {
      // Module and action IDs from the constants file
      documentTypePhotosId: 6,
      parentActionId: MODULE.INVENTORY.ActionId,
      moduleId: MODULE.INVENTORY.ModuleId,
      actionId: MODULE.INVENTORY.ActionId,
      attachments: [],
      uxDocumentTypeList: [],
      selectedDocumentType: null,
    };
  },
  computed: {
    /// <summary>
    /// Retrieves the modal status from the Vuex store.
    /// </summary>
    modalStatus() {
      return this.$store.getters.getModalStatus;
    },
    /// <summary>
    /// Determines whether the uploader component should be visible.
    /// It requires that a document type is selected and that modalStatus has valid data.
    /// </summary>
    isUploaderVisible() {
      return (
        this.selectedDocumentType &&
        this.modalStatus &&
        this.modalStatus.data &&
        this.modalStatus.data.id
      );
    },
    /// <summary>
    /// Determines if the file should be shared based on the modal status category.
    /// </summary>
    isFileShare() {
      return (
        this.modalStatus &&
        this.modalStatus.data &&
        this.modalStatus.data.category === "DocumentTemplate"
      );
    },
  },
  methods: {
    /// <summary>
    /// Handles a successful file upload event.
    /// </summary>
    /// <param name="file">The file object returned upon successful upload.</param>
    onUploadAttachment(file) {
     
      this.attachments.push(file);
    },
    /// <summary>
    /// Loads initial data for the component including the document types dropdown.
    /// </summary>
    async loadData() {
      try {
        // Fetch document types from the global service and set the default selection.
        this.uxDocumentTypeList = await globalServices.getUXDropDown(
          MODULE.DOCUMENTS.UX.DOCTYPES
        );
        this.selectedDocumentType = globalServices.setDropDownDefault(
          this.uxDocumentTypeList
        );
      } catch (error) {
        
      }
    },
  },
  mounted() {
    // Load dropdown data when the component is mounted
    this.loadData();
  },
};
</script>
