
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  import SmallCard from "@/components/_cards/small-card.vue";
  import GenericTable from "@/components/listTable/generic-table.vue";
  import UXTechnicianService from "@/services/ux/uxtechnicians";
  import ApplicationService from "@/services/application";
  import DashboardService from "@/services/dashboard";
  import { DashboardFilter } from "@/interfaces/filters/DashboardFilters";
  import BarChart from "@/components/charts/bar-chart.vue";
  import PieChart from "@/components/charts/pie-chart.vue";
  import { debounce } from "lodash/function";
  import moment from "moment";
  import EVENTS from "@/constants/events";
  import charts from "@/mixin/charts-mixin";
  
  // Instantiate services
  const app = new ApplicationService();
  let global = new GlobalServices();
  let tech = new UXTechnicianService();

  export default {
    mixins: [charts],
    data() {
      return {
        valueDate: [],
        listDataObj: {},
        EVENT_ACTION: {},
        actionId: null,
        moduleId: null,
        id: null,
        parentActionId: null,
        moduleName: null,
        dropDownList: [
          { displayName: "Website", id: 1 },
          { displayName: "Email", id: 2 },
          { displayName: "Referral", id: 3 },
        ],
        selectedRadio: "thismonth",
        options: [
          { text: "This Week", value: "thisweek" },
          { text: "Last Week", value: "lastweek" },
          { text: "This Month", value: "thismonth" },
          { text: "This Year", value: "thisyear" },
        ],
        mockStats: [
          {
            id: "1116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Total Hours Logged",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "purple",
          },
          {
            id: "4116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Regular Time",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "orange",
          },
          {
            id: "3116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Over Time",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "red",
          },
          {
            id: "2116b3e6-cc45-4c2d-8772-d086db31b0ec",
            displayTitle: "Travel Time",
            primaryNumber: "0",
            primarySymbol: "h",
            secondaryNumber: "",
            secondarySymbol: "",
            queryStringParamater: "1",
            displayDescription: "",
            color: "green",
          },
        ],
        barDataSets: {},
        pieDataSets: {},
        headers: [
          {
            id: 1,
            title: "Technician",
            columnName: "technician",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 10,
            title: "",
            columnName: "tooltip",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 2,
            title: "Date",
            columnName: "date",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
            width: "10%;",
          },
          {
            id: 3,
            title: "Reference Number",
            columnName: "referenceNumber",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
            clickable: true,
          },
          {
            id: 4,
            title: "Description",
            columnName: "description",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 5,
            title: "Type",
            columnName: "type",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 6,
            title: "Time In",
            columnName: "timeIn",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 7,
            title: "Time Out",
            columnName: "timeOut",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 8,
            title: "Travel",
            columnName: "travel",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
          {
            id: 9,
            title: "Total",
            columnName: "total",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
          },
        ],
        items: [],
        uxTechniciansList: [],
        technicianSelected: null,
        uxClientsList: [],
        clientSelected: null,
        uxWorkOrderList: [],
        workOrderSelected: null,
        filters: {},
        // Counter to track number of API polling calls made
        pollCount: 0,
        // Timer ID for the polling interval
        timer: null
      };
    },
    computed: {
      getSelectedRadioText() {
        const option = this.options.find((d) => d.value === this.selectedRadio);
        return option.text;
      },
      dateRangeDefaultValue() {
        const format: string = "DD-MMM-YYYY";
        const currentDate: string = moment().format(format);
        return [
          moment(currentDate)
            .add(1, "M")
            .format(format),
          currentDate,
        ];
      },
    },
    watch: {
      valueDate(value: any) {
        if (value && value.length === 2 && value[0] !== "" && value[1] !== "") {
          this.selectedRadio = null;
        } else {
          this.selectedRadio = "thismonth";
        }
        this.loadTable();
      },
      selectedRadio(value: any) {
        if (value) {
          if (this.valueDate.length > 0) this.valueDate = [];
          this.loadAllRequiredData();
        }
      },
    },
    components: {
      SmallCard,
      GenericTable,
      BarChart,
      PieChart,
    },
    methods: {
      /**
       * Handles search for work orders.
       * @param search - The search string.
       * @param loading - Callback to set loading state.
       */
      onSearchWorkOrder(search: string, loading: (state: boolean) => void) {
        if (search.length > 0) {
          loading(true);
          this.searchWorkOrder(loading, search, this);
        }
      },
      /**
       * Debounced method to search work orders to minimize API calls.
       */
      searchWorkOrder: debounce(async (loading: (state: boolean) => void, search: string, vm: any) => {
        vm.uxWorkOrderList = await global.getUXDropDown(
          MODULE.DASHBOARD.ActionId,
          null,
          null,
          null,
          null,
          null,
          { disabled: false, deleted: false },
          search
        );
        loading(false);
      }, 500),
      /**
       * Reloads the charts with updated parameters.
       */
      async reloadCharts() {
        const params: any = {
          actionId: MODULE.TASKS.ActionId,
          moduleId: MODULE.PROJECTS.ModuleId,
          DateFilter: this.selectedRadio,
        };

        if (this.clientSelected) {
          params.ClientId = this.clientSelected;
        }

        if (this.technicianSelected) {
          params.EmployeeId = this.technicianSelected;
        }

        if (this.workOrderSelected) {
          params.ProjectId = this.workOrderSelected.id;
        }

        if (this.valueDate && this.valueDate.length === 2 && this.valueDate[0] !== "" && this.valueDate[1] !== "") {
          params.StartDate = this.valueDate[0];
          params.EndDate = this.valueDate[1];
        }

        this.barDataSets = await this.getChartsData(
          params,
          "bar",
          "",
          false,
          ["Total Logged Hours", "Available Hours"],
          [false, true]
        );
        this.pieDataSets = await this.getChartsData(params, "pie", "TimeTrackingType", false);
      },
      /**
       * Opens a side panel for the selected project.
       * @param data - The project data containing at least the intProjectID.
       */
      async showPanel(data: any) {
        if (data) {
          // Set the path for the selected project and update the router
          const currentPath: string = this.$route.path + "/" + data.intProjectID;
          const route: any = { name: "projects-details", params: { id: data.intProjectID } };

          if (currentPath !== this.$route.path) {
            this.$router.push({ name: route.name, params: { id: `${data.intProjectID}` } });
          }

          // Cast the filter to DashboardFilter to satisfy TypeScript requirements.
          const filter = { projectRefNumber: data.intProjectID } as DashboardFilter;
          const dashboardService = new DashboardService();
          const results: any = await dashboardService.getProjects(filter);
          if (results.resources.length > 0) {
            const project: any = results.resources.find((p: any) => p.intProjectID === data.intProjectID);
            app.showSidePanel(null, project, route);
          }
        }
      },
      /**
       * Loads initial dashboard data.
       */
      async loadData() {
        this.id = 0;
        this.parentActionId = MODULE.PROJECTS.ActionId;
        this.moduleName = "Time Tracking";
        this.actionId = MODULE.TIMETRACKING.ActionId;
        this.moduleId = MODULE.PROJECTS.ModuleId;
        this.uxTechniciansList = await tech.getTechnicians();
        this.uxClientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId);
        this.uxWorkOrderList = await global.getUXDropDown(MODULE.DASHBOARD.ActionId);
        this.loadTable();
      },
      /**
       * Loads all required data (table and charts).
       */
      loadAllRequiredData() {
        this.loadTable();
        this.reloadCharts();
      },
      /**
       * Downloads the Excel report.
       */
      async downloadExcel() {
        const res: any = await global.getTableReportDataByActionId({
          ...this.filters,
          export: "excel",
        });
        if (!Array.isArray(res)) window.open(res, "_blank");
      },
      /**
       * Loads the table data based on current filters.
       */
      async loadTable() {
        try {
          // Set filters to be passed as prop values
          this.filters = {
            actionId: this.actionId,
            moduleId: this.moduleId,
          };

          if (this.clientSelected) {
            this.filters.ClientId = this.clientSelected;
          }

          if (this.selectedRadio) {
            this.filters.dateFilter = this.selectedRadio;
          }

          if (this.technicianSelected) {
            this.filters.EmployeeId = this.technicianSelected;
          }

          if (this.workOrderSelected) {
            this.filters.ProjectId = this.workOrderSelected.id;
          }

          if (this.valueDate && this.valueDate.length === 2 && this.valueDate[0] !== "" && this.valueDate[1] !== "") {
            this.filters.StartDate = this.valueDate[0];
            this.filters.EndDate = this.valueDate[1];
          }

          const results: any = await global.getDataReportsList(this.filters);
          this.listDataObj = results.table;
          const headers: any[] = this.listDataObj.header;
          headers.splice(1, 0, {
            id: 999,
            title: "",
            columnName: "tooltip",
            isSortable: false,
            isVisible: true,
            rightAlign: false,
            smallest: true,
          });
          this.headers = headers;
          this.items = this.listDataObj.data;
          this.mockStats = this.listDataObj.stats;
        } catch (e) {
          // Retry loading table data in case of error
          this.loadTable();
        }
      },
    },
    async mounted() {
      // Initial data load
      await this.loadData();
      await this.reloadCharts();

      // Set up polling to refresh data every 20 seconds and stop after 30 calls.
      this.timer = setInterval(async () => {
        if (this.pollCount >= 30) {
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        this.pollCount += 1;
        await this.loadTable();
        await this.reloadCharts();
      }, 20000); // 20-second interval
    },
    beforeDestroy() {
      // Clean up the polling timer on component unmount
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    created() {
      // Set event constants for use in the component
      this.EVENT_ACTION = EVENTS;
    },
  };
