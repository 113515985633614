
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import { mapState } from "vuex";
  import { IInfo } from "@/interfaces/IUXInfo";
  import SearchService from "@/services/search";

  // Create a SearchService instance.
  const searchService = new SearchService();

  export default {
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        EVENT_ACTION: {},
        stats: {
          data: [
            {
              id: 1,
              title: "Total",
              numberValue: "0",
              description: null,
              class: "ss-primary-font-color",
              border: "border-left-purple",
              uiName: null,
              isCurrency: false,
              isHighlightSection: false,
              subText: null,
            },
            {
              id: 2,
              title: "-",
              numberValue: "0",
              description: null,
              class: "ss-orange-font-color",
              border: "border-left-orange",
              uiName: null,
              isCurrency: false,
              isHighlightSection: false,
              subText: null,
            },
            {
              id: 3,
              title: "-",
              numberValue: "$0.00",
              description: null,
              class: "ss-primary-blue-font-color",
              border: "border-left-blue",
              uiName: null,
              isCurrency: false,
              isHighlightSection: false,
              subText: null,
            },
            {
              id: 3,
              title: "-",
              numberValue: "$0.00",
              description: null,
              class: "ss-primary-blue-font-color",
              border: "border-left-blue",
              uiName: null,
              isCurrency: false,
              isHighlightSection: false,
              subText: null,
            },
            {
              id: 5,
              title: "-",
              numberValue: "$0.00",
              description: null,
              class: "ss-info-bg",
              border: "",
              uiName: null,
              isCurrency: false,
              isHighlightSection: true,
              subText: "Tax: $0.00",
            },
            {
              id: 6,
              title: "-",
              numberValue: "$0.00",
              description: null,
              class: "ss-info-bg",
              border: "",
              uiName: null,
              isCurrency: false,
              isHighlightSection: true,
              subText: "Tax: $0.00",
            },
          ],
        },
        statTimer: null,   // Holds the interval timer ID.
        apiCallCount: 0,   // Tracks how many times the API has been called.
      };
    },
    computed: {
      ...mapState({
        infoFilter: (state: any) => state.Application.list.filter,
      }),
      infoData() {
        if (!this.stats.data) return;
        return this.stats.data.filter((i) => !i.isHighlightSection);
      },
      infoHighlightsData() {
        if (!this.stats.data) return;
        return this.stats.data.filter((i) => i.isHighlightSection);
      },
    },
    methods: {
      /**
       * onRequestToUpdateTotal: Updates the DOM element with the new total.
       */
      onRequestToUpdateTotal(newValue) {
        if (
          this.$refs.divGrandTotal === undefined ||
          this.$refs.divGrandTotal.length === 0
        )
          return;
        this.$refs.divGrandTotal[0].innerText = newValue;
      },
      /**
       * title: Returns a formatted title string.
       */
      title(title) {
        return title
          ? title.split(" ").join("-").toLowerCase()
          : Math.floor(Math.random());
      },
      /**
       * getStats: Retrieves stats from the API, caching the response for 15 minutes.
       * - Builds the filter based on props and Vuex state.
       * - Computes a unique cache key using the API URL and filter.
       * - Checks localStorage for a cached entry that isn’t expired.
       * - If cached data exists and is valid, uses it; otherwise, calls the API.
       * - Schedules subsequent calls every 30 seconds (up to 5 calls).
       */
      async getStats() {
        // Build the filter and determine the API URL.
        let info = {} as IInfo;
        info.isEnabled = false;
        let id = 0;
        let apiUrl = "global/list/info";
        let infoFilter = this.infoFilter;
        if (this.dataObj.details && this.dataObj.details.id) {
          id = this.dataObj.details.id;
          apiUrl = "global/details/info";
          if (infoFilter && infoFilter.id === 0) delete infoFilter.id;
        }
        let filter = {
          actionId: this.dataObj.actionId,
          id,
          ...infoFilter,
        };

        // Adjust filter for Lead or Prospect types when details are not present.
        if (
          this.dataObj &&
          this.dataObj.settings &&
          (this.dataObj.settings.clientType === "Lead" ||
            this.dataObj.settings.clientType === "Prospect") &&
          !this.dataObj.details
        ) {
          filter.only =
            this.dataObj.settings.clientType === "Lead"
              ? "ClientType:Lead"
              : "ClientType:Prospect";
        }

        // Compute a cache key based on the API URL and filter.
        const cacheKey = "statsCache_" + apiUrl + "_" + JSON.stringify(filter);
      

        // Set expiration to 15 minutes.
        const expiry = 15 * 60 * 1000;
        const now = Date.now();
        const cacheEntryStr = localStorage.getItem(cacheKey);

        if (cacheEntryStr) {
          try {
            const cacheEntry = JSON.parse(cacheEntryStr);
            if (now - cacheEntry.timestamp < expiry) {
            
              this.stats = cacheEntry.data;
              return;
            } else {
              
            }
          } catch (e) {
           
          }
        } else {
          
        }

        // Call the API using the SearchService.
        let results = await searchService.getResults(apiUrl, filter);
        if (results.success) {
          info.data = results.resources;
          info.isDetailsView = true;
          this.stats = info;
          // Cache the result with a timestamp.
          const cacheEntry = {
            timestamp: now,
            data: info,
          };
          localStorage.setItem(cacheKey, JSON.stringify(cacheEntry));
         
        }

        // Increment the API call count.
        this.apiCallCount++;

        // Setup the interval timer if not already set.
        if (!this.statTimer) {
          this.statTimer = setInterval(async () => {
            if (this.apiCallCount >= 5) {
              clearInterval(this.statTimer);
              this.statTimer = null;
              
              return;
            }
            await this.getStats();
          }, 30000); // 30-second interval.
        }
      },
    },
    mounted() {
      this.EVENT_ACTION = EVENTS;
      // The initial API call.
      this.getStats();
      // Subscribe to event updates.
      this.$root.$on(this.EVENT_ACTION.INFO.UpdateTotal, this.onRequestToUpdateTotal);
    },
    beforeDestroy() {
      if (this.statTimer) clearTimeout(this.statTimer);
      this.$root.$off(this.EVENT_ACTION.INFO.UpdateTotal);
    },
  };
