import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/config/prototypes";
import PortalVue from "portal-vue";
import BootstrapVue from "bootstrap-vue";
import vSelect from "vue-select";
import VueAudio from "vue-audio-better";
import "./assets/css/custom.css";
import "vue-select/dist/vue-select.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import IdleVue from "idle-vue"; //Track if user is idle to automatically log them out...

//Segment Analytics
// import VueSegmentAnalytics from "vue-segment-analytics";

//Form Validation
import {ValidationObserver, ValidationProvider, extend, localize} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

import VueCurrencyFilter from "vue-currency-filter";
import Vue2Filters from "vue2-filters";
import VueLodash from "vue-lodash";
import DatePicker from "vue2-datepicker";
import VueSimpleSuggest from "vue-simple-suggest";
import VueFriendlyIframe from "vue-friendly-iframe";
import VueSlideoutPanel from "vue2-slideout-panel";
import VueJsonToTable from "vue-json-to-table";

import GlobalSettingUniversalComp from "@/components/_universal/cta-global-settings.vue";
import GlobalFunctionMixin from "@/mixin/global-function-mixin";
import * as Sentry from "@sentry/browser";
import {Vue as VueIntegration} from "@sentry/integrations";
//import VueGtm from "vue-gtm";
import lodash from "lodash";

import moment from "moment";

// Import the Auth0 configuration
//import { domain, clientId } from "./auth_config.json";

const {domain, clientId} = require("../auth_config.json");

// Import the plugin here
import {Auth0Plugin} from "./auth";

// Azure maps
import VueAzureMaps from "vue-azure-maps";

//Components
import SidePanel from "@/layouts/side-panel-body.vue";
import VueMask from "v-mask";
Vue.use(VueMask);

Vue.filter("formatDate", function(value: any) {
  if (value) {
    return moment(String(value)).format("DD-MMM-YYYY LT");
  }
});

Vue.mixin(GlobalFunctionMixin);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState: any) => {
    appState.redirectUri = window.location.origin;
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

Vue.use(IdleVue, {
  store,
  idleTime: 7200000, //2Hours idle time.
  startAtIdle: false,
});
Vue.use(VueJsonToTable as any);
Vue.use(VueSlideoutPanel as any);
Vue.use(VueFriendlyIframe as any);

Vue.use(DatePicker as any);
Vue.use(PortalVue as any);
Vue.use(BootstrapVue as any);
Vue.use(VueAudio);
Vue.use(Vue2Filters);

//Components
Vue.component("v-select", vSelect);
Vue.component("vue-simple-suggest", VueSimpleSuggest);
Vue.component("side-panel", SidePanel);
Vue.component("global-settings-modal", GlobalSettingUniversalComp);
Vue.use(VueLodash, {name: "custom", lodash: lodash});
Vue.use(VueAzureMaps, {
  key: "tT95znOUgw4URzxsg4BtLWfbS1Wq2Ch0NSBhY-MoA_A",
});

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false,
});

// install rules and localization
Object.keys(rules).forEach((rule: any) => {
  extend(rule, rules[rule]);
});

localize("en", en);

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
