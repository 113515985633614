import axios from 'axios';
import API_CONFIG from '@/config/API_CONFIG';
import Router from "@/router";
import AuthService from "@/auth/authService";
// Import your cache service instance
import cacheService from '@/services/cache'; // Adjust the import path as needed

// Initialize API configuration and headers.
let config = new API_CONFIG();
let apiUrl = config.API_URL;
let apiKey = config.API_KEY;
let apiHeaderObj;

class UXInventoryService {
  constructor() {
    // Set up the header object with the API key.
    apiHeaderObj = {
      headers: {
        'x-functions-key': apiKey
      }
    };
  }
  
  /// Retrieves the inventory list, caching the result for 5 minutes.
  async getInventoryList(query) {
    // Create a unique cache key based on the query parameter.
    // If query is undefined, an empty string is used.
    const cacheKey = "inventoryList:" + (query || "");

    // Use cacheService.fetchWithCache to either return cached data (if valid)
    // or execute the API call and then cache the result.
    return await cacheService.fetchWithCache(
      cacheKey,
      async () => {
        // Default to an empty string if query is undefined.
        let q = query;
        if (q === undefined) {
          q = "";
        }
        
        // Retrieve authentication instance and construct the query string.
        let auth = AuthService.getInstance();
        let queryString = auth.getQueryString() + "&displayname=" + q.trimEnd();

        // Reinitialize API configuration to ensure up-to-date values.
        let config = new API_CONFIG();
        axios.defaults.baseURL = config.API_URL;

        // Make the API call to fetch inventory data.
        let results = await axios.get('/ux/inventory' + queryString, apiHeaderObj);

        // Return the 'resources' array from the API response.
        return results.data.resources;
      },
      300000 // Cache expiry time in milliseconds (5 minutes)
    );
  }
}

export default UXInventoryService;
