<template>
  <div class="content-wrapper w-100 pt-5 mt-4" id="content-wrapper">
    <!-- <router-view name="body" v-if="!iframe.showIframe && !this.isUsingMobile" /> -->
    <router-view name="body" v-if="!iframe.showIframe" />
    <slideout-panel></slideout-panel>

    <!-- data-hj-allow-iframe="true" attribute is used by hotjar.com to track iframe interaction -->
    <vue-friendly-iframe :class="[isScheduler ? 'schedulerHeight' : '', 'app-iframe']" v-if="iframe.showIframe" data-hj-allow-iframe="true" :src="iframeUrl" @load="onIframeLoad"></vue-friendly-iframe>
  </div>
</template>

<script>
import Dashboard from "@/views/Dashboard.vue";
import mobileMixins from "@/mixin/mobile-mixin";
import cacheService from "@/services/cache"; // Adjust the path as needed
  
export default {
  mixins: [mobileMixins],
  data() {
    return {
      currentComponent: Dashboard,
    };
  },
  computed: {
    iframeUrl() {
      return this.$store.getters.getIframeProperties.iframeUrl;
    },
    iframe() {
      return this.$store.getters.getIframeProperties;
    },
    isScheduler() {
      return this.$route.name === "scheduler";
    },
  },
  components: {
    // Dashboard
  },
  mounted() {
    // Clear the cache when the layout is mounted on a full refresh.
    cacheService.clearCache();
    
  

    // Check if Sentry is already loaded
    if (typeof window.Sentry === "undefined") {
    
      const script = document.createElement("script");
      script.src = "https://browser.sentry-cdn.com/7.52.0/bundle.min.js";
      script.crossOrigin = "anonymous";
      script.onload = () => {
       
        this.initSentry();
      };
      script.onerror = () => {
       
      };
      document.head.appendChild(script);
    } else {
      
      this.initSentry();
    }
  },
  methods: {
    /// <summary>
    /// Initializes Sentry and configures tracing and profiling.
    /// </summary>
    initSentry() {
      if (typeof window.Sentry !== "undefined") {
        
        // Create an array to hold integrations.
        let integrations = [];
        
        // Check and add Vue integration if available.
        if (typeof window.Sentry.vueIntegration === "function") {
          integrations.push(
            window.Sentry.vueIntegration({
              // Use the global Vue instance.
              Vue: window.Vue,
              tracingOptions: {
                trackComponents: ["Header", "Navigation", "Footer"],
                hooks: ["create", "mount"],
              },
            })
          );
          
        } else {
         
        }

        // Check and add BrowserTracing integration if available.
        if (window.Sentry.Integrations && window.Sentry.Integrations.BrowserTracing) {
          integrations.push(
            new window.Sentry.Integrations.BrowserTracing({
                tracingOrigins: [
                  "localhost",
                  /^\//,
                  "https://saturn-east.azurewebsites.net/api",
                  "https://saturn-central.azurewebsites.net/api"
                ],
              })
          );
          
        } else {
          
        }
        
        // Optionally add ProfilingIntegration if available.
        if (window.Sentry.ProfilingIntegration) {
          integrations.push(new window.Sentry.ProfilingIntegration());          
        } else {
          
        }

        try {
          window.Sentry.init({
            dsn: "https://edad118ee8783a9afc46d33405d32b07@o4508920552488960.ingest.us.sentry.io/4508920554061824",
            integrations: integrations,
            tracesSampleRate: 1.0,  // Full tracing in production, adjust as needed
            profilesSampleRate: 1.0,  // Full profiling in production, adjust as needed
            tracePropagationTargets: [
                          /^https:\/\/portal\.snapsuite\.io\/api/,
                          /^https:\/\/saturn-east\.azurewebsites\.net/,
                          /^https:\/\/saturn-central\.azurewebsites\.net/
                        ],
            beforeSend(event) {              
             
              return event; // Send event normally
            },
          });
         
        } catch (error) {
        
        }
        
        // Set up a manual Vue error handler to capture Vue errors.
        if (window.Vue) {
          window.Vue.config.errorHandler = function (err, vm, info) {
            window.Sentry.captureException(err);
            
          };
          
        }
      } else {
        
      }
    },

    /// <summary>
    /// Called when the iframe loads; updates the browser URL.
    /// </summary>
    onIframeLoad() {
      // When the iframe loads, change the browser URL
      let updateUrl = this.$store.getters.getIframeProperties.friendlyUrl;

      if (updateUrl) {
        this.$router.replace(updateUrl);
      }
    },
  },
};
</script>
