<template>
  <div class="scrollbar slide-in-scroller from-right slide-panel ss-light-grey-bg u-height-100vh" :class="{SidePanelWithIframe: sidePanel.isSidePanelFrame}" @click="hideDropdown">
    <div class="slide-in-content">
      <router-view :key="$route.fullPath" name="sidepanel"/>
    </div>
  </div>
</template>

<script>
  import ProjectDetails from "@/views/projects/details";
  import EVENTS from "@/constants/events";
  import MODULE from "@/constants/modules";
  import {mapState} from "vuex";

  export default {
    data() {
      return {
        hide: false,
        sidePanel: null,
      };
    },

    methods: {
      async loadData() {
        //Load data from store for side panel
        this.sidePanel = this.$store.getters.getSidePanel;
      },
      hideDropdown() {
        // this.$refs.dropdown.hide(true);
      },
    },
    computed: {},
    components: {
      // ProjectDetails
    },
  mounted() {
      
    },
    async created() {
      this.EVENT_ACTION = EVENTS;
      await this.loadData();
    },
  };
</script>
