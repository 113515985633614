
  import Vue from "vue";
  import QuoteServices from "@/services/quotes";
  import TemplateServices from "@/services/templates";
  import EditDataObject from "@/interfaces/EditDataObject";
  import UXInventoryService from "@/services/ux/uxinventory";
  import "vue-simple-suggest/dist/styles.css"; // Optional CSS
  import GlobalServices from "@/services/global";
  import MODULE from "@/constants/modules";
  import editMixin from "@/mixin/edit-mixin";

  // Create service instances.
  const templateApi = new TemplateServices();
  const quoteApi = new QuoteServices();
  const global = new GlobalServices();

  export default Vue.extend({
    name: "QuoteDetails",
    mixins: [editMixin],
    props: {
      dataObj: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        id: 0,
        // Object holding the template fields
        template: {
          description: "",
          termsSection2: "",
          termsSection3: "",
        },
        selectedItem: {},
        quoteDetails: {},
        showErrorWarning: false,
        subTotal: 0,
        uxTemplateList: [] as any[],
        // Timer used for 500ms debounce
        timer: null as any,
        config: {
          toolbarButtons: [
            ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript"],
            ["fontFamily", "fontSize", "textColor", "backgroundColor"],
            ["inlineClass", "inlineStyle", "clearFormatting"],
          ],
          height: 600,
          events: {
            "froalaEditor.initialized": function() {},
          },
          key: "YNB3fJ3B8C10D6B5D2A-9rlvqgkD6zdjI-8I-7B-22fdtB1zH-9iB3B9B6D5C2C4D3H3G3H3==",
          attribution: false,
        },
        // Flag to disable editing if the quote is locked.
        isDisabled: false,
      };
    },
    methods: {
      // Debounce posting data to server after 500ms.
      async updateData(dataObj: EditDataObject): Promise<void> {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          global.postData(dataObj);
        }, 500);
      },
      // Update a field in the template (or quote) details.
      updateField(propertyName: string, propertyValue: any, displayName: string): void {
        const editObj = {} as EditDataObject;
        editObj.propertyName = propertyName;
        editObj.propertyValue = propertyValue;
        editObj.displayName = displayName;
        editObj.id = this.dataObj.data.id;
        editObj.actionId = MODULE.QUOTATION.ActionId;
        editObj.referenceNumber = this.quoteDetails.qutoteNumber;
        this.updateData(editObj);
      },
      async onSelectTerms(event: any) {
        // Called when a new template is selected from the dropdown.
        this.loadSelectedTemplate(event.id);
      },
      async loadSelectedTemplate(id: number) {
        // When the dropdown changes, call the API to get the template details.
        const templateDetail = await global.getEditDetails(this.dataObj.param.actionId, id);
        this.template = await templateApi.getTemplateById(id);
        this.template.description = 
          templateDetail && templateDetail.isHTML && !templateDetail.isPlainText
            ? templateDetail.Description
            : templateDetail.PlainText;
        this.template.termsSection2 = templateDetail.TemplateData;
        this.template.termsSection3 = templateDetail.Notes;

        // Introduce a 1-second pause between updates
        const pause = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

        // Update Database with each field.
        this.updateField("termsConditions", this.template.description, "Terms & Conditions");
        await pause(1000);
        this.updateField("ContentSection3", this.template.termsSection2, "Terms & Conditions Section 2");
        await pause(1000);
        this.updateField("RequireDepositAdvanceLabel", this.template.termsSection3, "Payment/Pricing Terms");
      },
      async initializeData() {
        // Get the quote details from the server.
        this.quoteDetails = await global.getEditDetails(MODULE.QUOTATION.ActionId, this.dataObj.data.id);

        // Get the quote terms (for the description) from the API.
        this.template.description = await quoteApi.getQuoteTermsById(this.dataObj.data.id);
        this.template.termsSection2 = this.quoteDetails.ContentSection3;
        this.template.termsSection3 = this.quoteDetails.RequireDepositAdvanceLabel;

        // Load the list of available templates.
        this.uxTemplateList = await global.getUXDropDown(
          MODULE.TEMPLATES.ActionId,
          MODULE.QUOTATION.ModuleId,
          MODULE.QUOTATION.TemplateTypeId
        );

        // If no terms exist, load the default template.
        if (!this.template.description) {
          this.selectedItem = this.uxTemplateList.find((list: any) => list.isDefault == true);
          if (this.selectedItem) {
            this.loadSelectedTemplate(this.selectedItem.id);
          }
        }

        // Set isDisabled based solely on the server value.
        // Normalize the value to a lowercase string and compare with "true".
        this.isDisabled = String(this.quoteDetails.IsLockedFromEditing).toLowerCase() === "true";
      },
    },
    async created() {
      await this.initializeData();
    },
  });
