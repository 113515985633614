<template>
  <div class="container-fluid dashboard body-color">
    <b-tabs content-class="mt-3">
      <b-tab active id="dashboard-tab-dashboard" lazy>
        <template v-slot:title>
          <!-- <span class="badge badge-warning">New</span> &nbsp; -->
          <strong>Dashboard</strong>
        </template>
        <Summary />
        <Highlights />
        <Filters />
        <EditableTable />
      </b-tab>
      <b-tab id="dashboard-tab-technician-report" lazy>
        <template v-slot:title>
          <!-- <span class="badge badge-warning">New</span> &nbsp; -->
          <strong>Technician Reports</strong>
        </template>
        <TechinicianDashboard />
      </b-tab>

      <b-tab id="dashboard-tab-manager-report" lazy v-if="$isDev">
        <template v-slot:title>
          <!-- <span class="badge badge-warning">New</span> &nbsp; -->
          <strong>Manager Reports</strong>
        </template>
        <ManagerReports />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import Summary from "@/components/dashboard/Summary.vue";
  import Highlights from "@/components/dashboard/Highlights.vue";
  import Filters from "@/components/dashboard/Filters.vue";
  import EditableTable from "@/components/table/EditableTable.vue";
  import EVENTS from "@/constants/events";
  import TechinicianDashboard from "@/components/dashboard/technician-dashboard.vue";
  import ManagerReports from "@/components/dashboard/ManagerReports.vue";

  export default {
    name: "dashboard",
    components: {
      Summary,
      Highlights,
      Filters,
      EditableTable,
      TechinicianDashboard,
      ManagerReports,
    },
    data() {
      return {
        EVENT_ACTION: {},
      };
    },
    computed: {
      isOldDashboardEnabled() {
        return process.env.VUE_APP_SHOW_OLD_DASHBOARD === "true";
      },
      filters() {
        return this.$store.getters.getFilter;
      },
    },
    methods: {
      async loadDashboard() {
        if ((this.filters && Object.keys(this.filters).length === 0) || !this.filters) {
          await this.$store.dispatch("getProjectsAction");
        } else {
          if ((this.filters && this.filters.maximumRows && this.filters.maximumRows !== 300) || (this.filters && !this.filters.maximumRows)) {
            this.$root.$emit("filters-timer", true);
            await this.$store.dispatch("getNextFilterResults", this.filters);
          }
        }
      },
      handleScroll() {
        // calculate the distance from the bottom of the page
        const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        // const html = document.documentElement;
        // const height = Math.max(document.body.scrollHeight, document.body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) / 2;
        const eightyPercentHeight = 0.5 * document.body.scrollHeight;
        // if the user has scrolled to the bottom of the page, load more items
        // if (distanceFromBottom <= height) {
        if (distanceFromBottom <= eightyPercentHeight) {
          this.loadDashboard();
        }
      },
    },
    async mounted() {
      //ONLY TRACK EVENTS IN PRODUCTION
      // if (process.env.VUE_APP_ENVIRONMENT === "production") {
      //   window.analytics.page("Dahsboard");
      // }
      window.addEventListener("scroll", this.handleScroll);
    },
    async created() {
      this.$store.commit("setPage", 1);
      await this.loadDashboard();

      this.$store.dispatch("showfullNavigation", true);

      //Import event constants and set local variable
      this.EVENT_ACTION = EVENTS;

      //Subscribe to dashboard refresh action
      this.$root.$on(this.EVENT_ACTION.DASHBOARD.Update, this.loadDashboard);
    },
    beforeDestroy() {
      //UnSubscribe to dashboard refresh action
      this.$root.$off(this.EVENT_ACTION.DASHBOARD.Update, this.loadDashboard);
      window.removeEventListener("scroll", this.handleScroll);
    },
  };
</script>
