/**
 * cache.ts
 *
 * A simple localStorage-based caching service.
 * This implementation maintains an in-memory cache and saves it to localStorage
 * under a single key ("snapsuite_app_cache"). Data is cached per unique key,
 * and you can clear the cache (for example on full page refresh).
 */
class CacheService {
  private readonly CACHE_KEY: string = 'snapsuite_app_cache';
  private inMemoryCache: Record<string, any> = {};

  constructor() {
    if ((process as any).client) {
     
      const stored = localStorage.getItem(this.CACHE_KEY);
      if (stored) {
        try {
          this.inMemoryCache = JSON.parse(stored);
          
        } catch (err) {
         
          this.inMemoryCache = {};
        }
      }
    }
  }

  loadCache(): Record<string, any> {
    if ((process as any).client) {
      const stored = localStorage.getItem(this.CACHE_KEY);
      if (stored) {
        try {
          this.inMemoryCache = JSON.parse(stored);
        } catch (err) {
         
          this.inMemoryCache = {};
        }
      }
    }
    return this.inMemoryCache;
  }

  saveCache(cache: Record<string, any>): void {
    this.inMemoryCache = cache;
    if ((process as any).client) {
      localStorage.setItem(this.CACHE_KEY, JSON.stringify(cache));
    
    }
  }

  clearCache(): void {
    this.inMemoryCache = {};
    if ((process as any).client) {
      localStorage.removeItem(this.CACHE_KEY);
     
    }
  }

  /**
   * fetchWithCache: Checks for a cached value using a unique key.
   * If found and not expired, returns it. Otherwise, calls the API,
   * stores the result with a timestamp, and returns the new data.
   *
   * @param key A unique key to identify the cached data.
   * @param apiCall An async function to call the API if no valid cache is found.
   * @param expiry Expiration time in milliseconds (default: Infinity).
   * @returns A promise resolving to the data.
   */
  async fetchWithCache<T>(key: string, apiCall: () => Promise<T>, expiry: number = Infinity): Promise<T> {
    const cache = this.loadCache();
    if (cache[key]) {
      const entry = cache[key];
      if (entry.timestamp && (Date.now() - entry.timestamp < expiry)) {
       
        return entry.data;
      } else {
       
      }
    }
  
    const data = await apiCall();
    cache[key] = { timestamp: Date.now(), data: data };
    this.saveCache(cache);
   
    return data;
  }
}

export default new CacheService();
