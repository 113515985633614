<template>
  <div>
    <b-tabs v-model="tabIndex" content-class="mt-3" class="position-relative" style="top:-15px">
      <!-- Basic Tab -->
      <b-tab title="Basic" active id="slidepanel-client-details-tabs-equipment-basic">
        <div class="row">
          <!-- Equipment Name Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Equipment Name</span>
            <ValidationProvider name="equipment_name" rules="required" v-slot="{errors}">
              <input
                class="form-control"
                type="text"
                id="slidepanel-client-details-tabs-equipment-basic-equipment-name"
                v-model="modalDataDetails.Name"
                @keyup="
                  updateFieldSimplified(
                    'Name',
                    modalDataDetails.Name,
                    'Equipment Name',
                    true
                  )
                "
              />
              <span class="text-danger">{{ errors.length > 0 ? 'Equipment Name is required.' : '' }}</span>
            </ValidationProvider>
          </div>
          <!-- Location Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Location</span>
            <v-select
              id="slidepanel-client-details-tabs-equipment-basic-location"
              :value="getSelectedDropDownValue('uxClientLocation', 'IntInventoryLocationID', true)"
              :options="uxLocationList"
              @input="handleBasicSelect('Location', $event.displayName)"
              label="displayName"
              :clearable="false"
            ></v-select>
          </div>
          <!-- Category Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Category</span>
            <v-select
              id="slidepanel-client-details-tabs-equipment-basic-category"
              :options="modalUXData.uxInventoryCategory"
              :value="getSelectedDropDownValue('uxInventoryCategory', 'IntInventoryCategoryID', true)"
              @input="handleBasicSelect('Category', $event.displayName)"
              label="displayName"
            ></v-select>
          </div>
          <!-- Description Section -->
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Description</span>
            <input
              class="form-control"
              id="slidepanel-client-details-tabs-equipment-basic-description"
              type="text"
              v-model="modalDataDetails.Description"
              @keyup="
                updateFieldSimplified(
                  'Description',
                  modalDataDetails.Description,
                  'Description',
                  true
                )
              "
            />
          </div>
          <!-- Make Section -->
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Make</span>
            <input
              class="form-control"
              id="slidepanel-client-details-tabs-equipment-basic-make"
              type="text"
              v-model="modalDataDetails.Make"
              @keyup="
                updateFieldSimplified(
                  'Make',
                  modalDataDetails.Make,
                  'Make',
                  true
                )
              "
            />
          </div>
          <!-- Model Number Section -->
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Model Number</span>
            <input
              class="form-control"
              id="slidepanel-client-details-tabs-equipment-basic-model-number"
              type="text"
              v-model="modalDataDetails.ModelNumber"
              @keyup="
                updateFieldSimplified(
                  'ModelNumber',
                  modalDataDetails.ModelNumber,
                  'Model Number',
                  true
                )
              "
            />
          </div>
          <!-- Location on Site Section -->
          <div class="col-md-12 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Location on Site</span>
            <input
              class="form-control"
              id="slidepanel-client-details-tabs-equipment-basic-location-on-site"
              type="text"
              v-model="modalDataDetails.LocationOnSite"
              @keyup="
                updateFieldSimplified(
                  'LocationOnSite',
                  modalDataDetails.LocationOnSite,
                  'Location on Site',
                  true
                )
              "
            />
          </div>
          <!-- Serial Number Section -->
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Serial Number</span>
            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-equipment-basic-serial-number"
              v-model="modalDataDetails.SerialNumber"
              @keyup="
                updateFieldSimplified(
                  'SerialNumber',
                  modalDataDetails.SerialNumber,
                  'Serial Number',
                  true
                )
              "
            />
          </div>
          <!-- Manufacturer/Supplier Section -->
          <div class="col-md-6 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Manufacturer/Supplier</span>
            <input
              class="form-control"
              type="text"
              id="slidepanel-client-details-tabs-equipment-basic-manufacturer"
              v-model="modalDataDetails.Manufacturer"
              @keyup="
                updateFieldSimplified(
                  'Manufacturer',
                  modalDataDetails.Manufacturer,
                  'Manufacturer/Supplier',
                  true
                )
              "
            />
          </div>
          <!-- Notes/Comments Section -->
          <div class="col-md-12">
            <span class="c-toolbar__state-title u-text-capitalize">Notes/Comments</span>
            <textarea
              class="form-control"
              id="slidepanel-client-details-tabs-equipment-basic-notes"
              rows="6"
              v-model="modalDataDetails.Notes"
              @keyup="
                updateFieldSimplified(
                  'Notes',
                  modalDataDetails.Notes,
                  'Notes',
                  true
                )
              "
            ></textarea>
          </div>
        </div>
      </b-tab>
      <!-- End of Basic Tab -->

      <!-- Details Tab -->
      <b-tab title="Details" id="slidepanel-client-details-tabs-equipment-details">
        <div class="row">
          <!-- Type Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Type</span>
            <v-select
              :options="typeOptions"
              v-model="modalDataDetails.EquipmentType"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-details-type"
              :clearable="false"
              @input="handleDetailsSelect('EquipmentType', $event.displayName)"
            ></v-select>
          </div>
          <!-- Quantity Section -->
          <!-- <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Quantity</span>
            <input
              class="form-control"
              type="number"
              v-model="modalDataDetails.Quantity"
              id="slidepanel-client-details-tabs-equipment-details-quantity"
              @keyup="handleDetailsInput('Quantity', modalDataDetails.Quantity)"
            />
          </div> -->
          <!-- Service Rate Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Service Rate</span>
            <input
              class="form-control"
              type="number"
              v-model="modalDataDetails.ServiceRate"
              id="slidepanel-client-details-tabs-equipment-details-service-rate"
              @keyup="handleDetailsInput('ServiceRate', modalDataDetails.ServiceRate)"
            />
          </div>
          <!-- Group Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Group</span>
            <v-select
              :options="groupOptions"
              v-model="modalDataDetails.EquipmentGroup"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-details-group"
              :clearable="false"
              @input="handleDetailsSelect('EquipmentGroup', $event.displayName)"
            ></v-select>
          </div>
          <!-- Install Date Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Install Date</span>
            <date-picker
              v-model="modalDataDetails.InstallDate"
              format="DD-MMM-YYYY"
              id="slidepanel-client-details-tabs-equipment-details-install-date"
              style="width: 100%;"
              lang="en"
              @input="handleDetailsInput('InstallDate', modalDataDetails.InstallDate)"
            ></date-picker>
          </div>
          <!-- Last Service Date Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Last Service Date</span>
            <date-picker
              v-model="modalDataDetails.LastServiceDate"
              format="DD-MMM-YYYY"
              id="slidepanel-client-details-tabs-equipment-details-last-service-date"
              style="width: 100%;"
              lang="en"
              @input="handleDetailsInput('LastServiceDate', modalDataDetails.LastServiceDate)"
            ></date-picker>
          </div>
          <!-- Next Service Date Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Next Service Date</span>
            <date-picker
              v-model="modalDataDetails.NextServiceDate"
              format="DD-MMM-YYYY"
              id="slidepanel-client-details-tabs-equipment-details-next-service-date"
              style="width: 100%;"
              lang="en"
              @input="handleDetailsInput('NextServiceDate', modalDataDetails.NextServiceDate)"
            ></date-picker>
          </div>
          <!-- Warranty Expiry Date Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Warranty Expiry Date</span>
            <date-picker
              v-model="modalDataDetails.WarrantyExpiryDate"
              format="DD-MMM-YYYY"
              id="slidepanel-client-details-tabs-equipment-details-warranty-expiry-date"
              style="width: 100%;"
              lang="en"
              @input="handleDetailsInput('WarrantyExpiryDate', modalDataDetails.WarrantyExpiryDate)"
            ></date-picker>
          </div>
          <!-- Status Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Status</span>
            <v-select
              :options="statusOptions"
              v-model="modalDataDetails.EquipmentStatus"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-details-status"
              :clearable="false"
              @input="handleDetailsSelect('EquipmentStatus', $event.displayName)"
            ></v-select>
          </div>
        </div>
      </b-tab>
      <!-- End of Details Tab -->

      <!-- Custom Tab -->
      <b-tab title="Custom" id="slidepanel-client-details-tabs-equipment-custom">
        <div class="row">
          <!-- Filter Size Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Filter Size</span>
            <v-select
              :options="filterSizeOptions"
              v-model="modalDataDetails.FilterSize"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-filter-size"
              :clearable="false"
              @input="handleCustomSelect('FilterSize', $event.displayName)"
            ></v-select>
          </div>
          <!-- Belt Size Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Belt Size</span>
            <v-select
              :options="beltSizeOptions"
              v-model="modalDataDetails.BeltSize"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-belt-size"
              :clearable="false"
              @input="handleCustomSelect('BeltSize', $event.displayName)"
            ></v-select>
          </div>
          <!-- Refrigerant Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Refrigerant</span>
            <v-select
              :options="refrigerantOptions"
              v-model="modalDataDetails.Refrigerant"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-refrigerant"
              :clearable="false"
              @input="handleCustomSelect('Refrigerant', $event.displayName)"
            ></v-select>
          </div>
          <!-- Tonnage Section -->
          <!-- <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Tonnage</span>
            <v-select
              :options="tonnageOptions"
              v-model="modalDataDetails.Tonnage"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-tonnage"
              :clearable="false"
              @input="handleCustomSelect('Tonnage', $event.value)"
            ></v-select>
          </div> -->
          <!-- Year Built Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Year</span>
            <v-select
              :options="yearOptions"
              v-model="modalDataDetails.YearBuilt"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-year"
              :clearable="false"
              @input="handleCustomSelect('YearBuilt', $event.displayName)"
            ></v-select>
          </div>
          <!-- Area Served Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Area Served</span>
            <v-select
              :options="areaServedOptions"
              v-model="modalDataDetails.AreaServed"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-area-served"
              :clearable="false"
              @input="handleCustomSelect('AreaServed', $event.displayName)"
            ></v-select>
          </div>
          <!-- BTU Section -->
          <!-- <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">BTU</span>
            <v-select
              :options="btuOptions"
              v-model="modalDataDetails.BTU"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-BTU"
              :clearable="false"
              @input="handleCustomSelect('BTU', $event.displayName)"
            ></v-select>
          </div> -->
          <!-- Expense Account Section -->
          <!-- <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Expense Account</span>
            <v-select
              :options="expenseAccountOptions"
              v-model="modalDataDetails.ExpenseAccount"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-expense-account"
              :clearable="false"
              @input="handleCustomSelect('ExpenseAccount', $event.displayName)"
            ></v-select>
          </div> -->
          <!-- Income Account Section -->
          <div class="col-md-4 u-mb-xsmall">
            <span class="c-toolbar__state-title u-text-capitalize">Income Account</span>
            <v-select
              :options="incomeAccountOptions"
              v-model="modalDataDetails.IncomeAccount"
              label="displayName"
              id="slidepanel-client-details-tabs-equipment-custom-income-account"
              :clearable="false"
              @input="handleCustomSelect('IncomeAccount', $event.displayName)"
            ></v-select>
          </div>
        </div>
      </b-tab>
      <!-- End of Custom Tab -->

     
     

       <!-- Photo Upload -->
        <b-tab v-if="modalObj.isInEditMode" title="Upload" id="slidepanel-client-details-tabs-equipment-photos">
        <div class="row">         
            <NewEquipmentPhotoUpload :inventoryId=inventoryId />
        </div>
      </b-tab>
      

            <b-tab v-if="modalObj.isInEditMode" title="Photos/Documents" id="slidepanel-client-details-tabs-equipment-gallery">
        <div class="row">         
         <EquipmentPhotoGallery :equipmentId="inventoryId" />
        </div>
      </b-tab>


    </b-tabs>
  </div>
</template>

<script>
import GlobalServices from "@/services/global";
let global = new GlobalServices();
import NewEquipmentPhotoUpload from "@/components/_modals/new-equipment-photo-upload.vue";
import EquipmentPhotoGallery from "@/components/_modals/new-equipment-gallery.vue";
import MODULE from "@/constants/modules";
import APPPLICATION from "@/constants/application";
import editMixin from "@/mixin/edit-mixin";

export default {
  mixins: [editMixin],
  components: {
    NewEquipmentPhotoUpload,EquipmentPhotoGallery
  },
  data() {
    return {
      inventoryId: 0,
      isOutOfService: false,
      shouldAutoAddToJob: false,
      selectedEquipment: null,
      uxEquipmentList: [],
      uxLocationList: [],
      selectedLocation: null,
      tabIndex: 0,
      clientsList: [],
      clientsSelected: [],

      // Dropdown options with sensible defaults
      typeOptions: [
        { displayName: 'HVAC' },
        { displayName: 'Electrical' },
        { displayName: 'Garage Door' },
        { displayName: 'Plumbing' },
        { displayName: 'Heavy Duty Equipment' }
      ],
      groupOptions: [
        { displayName: 'Group A' },
        { displayName: 'Group B' },
        { displayName: 'Group C' }
      ],
      statusOptions: [
        { displayName: 'Active' },
        { displayName: 'Inactive' },
        { displayName: 'Maintenance' }
      ],
      filterSizeOptions: [
        { displayName: 'Small' },
        { displayName: 'Medium' },
        { displayName: 'Large' }
      ],
      beltSizeOptions: [
        { displayName: 'Small Belt' },
        { displayName: 'Medium Belt' },
        { displayName: 'Large Belt' }
      ],
      refrigerantOptions: [
        { displayName: 'R-22' },
        { displayName: 'R-410A' },
        { displayName: 'R-134a' }
      ],
      tonnageOptions: [
        { displayName: '1 Ton', value: 1 },
        { displayName: '2 Tons', value: 2 },
        { displayName: '3 Tons', value: 3 },
        { displayName: '4 Tons', value: 4 },
        { displayName: '5 Tons', value: 5 }
      ],
      yearOptions: [
        { displayName: '2023' },
        { displayName: '2024' },
        { displayName: '2025' },
        { displayName: '2026' },
        { displayName: '2027' },
        { displayName: '2028' },
        { displayName: '2029' },
        { displayName: '2030' },
        { displayName: '2031' },
        { displayName: '2032' }
      ],
      areaServedOptions: [
        { displayName: 'North' },
        { displayName: 'South' },
        { displayName: 'East' },
        { displayName: 'West' }
      ],
      btuOptions: [
        { displayName: '5000 BTU', value: 5000 },
        { displayName: '8000 BTU', value: 8000 },
        { displayName: '10000 BTU', value: 10000 },
        { displayName: '12000 BTU', value: 12000 },
        { displayName: '15000 BTU', value: 15000 }
        
      ],
      expenseAccountOptions: [
        { displayName: 'Expense Account A' },
        { displayName: 'Expense Account B' },
        { displayName: 'Expense Account C' }
      ],
      incomeAccountOptions: [
        { displayName: 'Income Account A' },
        { displayName: 'Income Account B' },
        { displayName: 'Income Account C' }
      ],
    };
  },
  beforeDestroy() {
    this.$root.$off("on-save", this.resetForm);
  },
  mounted() {
    this.$root.$on("on-save", this.resetForm);
  },
  async created() {
    this.APP = APPPLICATION;
    let modalDetails = {};
    let modalUXData = {};
    let module = MODULE.EQUIPMENT;
    let page = this.APP.MODAL.EQUIPMENT;

    //Convert modalObj.data.id to number for inventoryId
    this.inventoryId = parseInt(this.modalObj.data.id);

    
    // Get edit data
    if (this.modalObj.isInEditMode) {
      // Load Details from API
      modalDetails = await global.getEditDetails(module.ActionId, this.modalObj.data.id);
      if (modalDetails.IntClientID) {
        modalDetails.clientId = modalDetails.IntClientID;
      }
      // TODO: Load primary contact on new modal
      // DropDown List Values - Call back function from controller, getTabSection to load related dropdown menu values...
      modalUXData = await this.modalObj.dataObj.uxdata(page);
      if (modalDetails) {
        this.isOutOfService = modalDetails.IsOutOfService ? true : false;
        this.shouldAutoAddToJob = modalDetails.shouldAutoAddToJob ? true : false;
      }
    } else {
      // New Item

      // Set Reference ID
      if (this.$route.name === "client-details") {
        // Check if the route name is from client so let set it to module id of client
        modalDetails.moduleId = MODULE.CLIENTS.ModuleId;
      } else {
        modalDetails.moduleId = module.ModuleId;
      }
      modalUXData.uxInventoryCategory = await global.getUXDropDown(MODULE.INVENTORY.UX.InventoryCategory, null, null, null);
      modalDetails.clientId = this.modalObj.data.id;
    }
    await this.$store.dispatch("setModalDataDetailsObject", modalDetails);
    await this.$store.dispatch("setModalUXData", modalUXData);
    await this.initializeData();
  },
  watch: {
    isOutOfService(value) {
      this.updateFieldSimplified("IsOutOfService", value, "Is Out of Service", true);
    },
    shouldAutoAddToJob(value) {
      this.updateFieldSimplified("ShouldAutoAddToJob", value, "Should Auto Add To Job", true);
    },
    tabIndex(value) {
      if (value === 3) {
        // tenants
        this.getClientList();
      }
    },
  },
  methods: {
    resetForm() {
      this.selectedEquipment = null;
    },
    async getClientList() {
      let clientsList = await global.getUXDropDown(MODULE.CLIENTS.ActionId, null, null, null, null, this.modalObj.data.id);
      this.clientsList = clientsList || [];
    },
    // Handle input for Basic Tab selections
    handleBasicSelect(fieldName, value) {
      this.onDropdownSelectionChange(
        value,
        fieldName,
        `ux${fieldName}`,
        `IntInventory${fieldName}ID`,
        true
      );
      this.updateFieldSimplified(
        fieldName,
        value,
        fieldName,
        true
      );
    },
    // Handle input for Details Tab selections
    handleDetailsSelect(fieldName, value) {
      this.updateFieldSimplified(
        fieldName,
        value,
        fieldName.replace(/([A-Z])/g, ' $1').trim(),
        true
      );
    },
    // Handle input for Custom Tab selections
    handleCustomSelect(fieldName, value) {
      this.updateFieldSimplified(
        fieldName,
        value,
        fieldName.replace(/([A-Z])/g, ' $1').trim(),
        true
      );
    },
    // Handle input for Details Tab text and date fields
    handleDetailsInput(fieldName, value) {
      this.updateFieldSimplified(
        fieldName,
        value,
        fieldName.replace(/([A-Z])/g, ' $1').trim(),
        true
      );
    },
    // Handle input for Custom Tab fields if any text inputs are present (not in current template)
    handleCustomInput(fieldName, value) {
      this.updateFieldSimplified(
        fieldName,
        value,
        fieldName.replace(/([A-Z])/g, ' $1').trim(),
        true
      );
    },
    async initializeData() {
      // Get Inventory List
      let equipmentList = await global.getUXDropDown(MODULE.INVENTORY.ActionId);
      this.uxEquipmentList = equipmentList || [];
      // Location list
      if (this.modalDataDetails && this.modalDataDetails.clientId) {
        let locationList = await global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, {
          clientId: this.modalDataDetails.clientId,
          projectId: 0,
        });
        this.uxLocationList = locationList || [];
      }
    },
    // Assuming updateFieldSimplified is defined in the mixin
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>