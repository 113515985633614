//Controller
import BaseController from "@/controllers/base-controller";

//Standard Components
import GlobalServices from "@/services/global";
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";
import UtilityString from "@/utilities/strings";
import {isDev} from "@/config/prototypes";
import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import {IController} from "@/interfaces/IController";
import {IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs} from "@/interfaces/UXSidePanel";
import {IInfo} from "@/interfaces/IUXInfo";
import SearchService from "@/services/search";
import cacheService from "@/services/cache";


class ProjectController implements IController {
  private config = new API_CONFIG();
  private auth = AuthService.getInstance();
  private utility = new UtilityString();
  private app = new ApplicationService();
  private global = new GlobalServices();

  private search = new SearchService();

  _actionId = MODULE.PROJECTS.ActionId;
  _moduleId = MODULE.PROJECTS.ModuleId;

  //Standard application event types, to standardize event logs and modal events
  EVENT_ACTION = EVENTS;

  //Stores the full data object state of the side panel to be returned.
  _sidepanel: IUXSidePanel = new Object() as IUXSidePanel;

  //Stores the default action, if the side panel action button is clicked without choosing an option from the list
  _defaultAction: IButtonAction = new Object() as IButtonAction;

  //The data object being passed in, eg.. Project, Client , Quote Data etc..
  _data = null;

  //Standard functionality such as print, email, close are stored in the base controller
  _base = new BaseController();

  //Reference to project earth for backward compability
  earth = new EarthApplicationService();

  //Constructor Takes in a Module Data Object (Project, Client, Invoice)
  constructor() {}

  //Page Header / Title
  getTitle(): String {
    let title = this._base.getTitle("Work Order", this._data.projectRefNumber, this._data.status);

    return title;
  }

  getSubTitle() {
    let address = this._data.location + ", " + this._data.province + ", " + this._data.postalCode;

    let subtitle = this._base.getSubTitle(this._data.businessName, address);

    return subtitle;
  }

  //Generate Drop Down Menu for Action Items
  getDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Purchase Order
    let purchaseOrderUrl = this.earth.getNewPurchaseOrderNavigationUrl(this._data.intProjectID);

    //  actions.push({
    //     id: 1,
    //     eventType: this.EVENT_ACTION.PURCHASEORDER.New,
    //     menuTitle: "Create a Purchase Order",
    //     modalTitle: "Create New Purchase Order",
    //     saveButtonTitle: "Create Purchase Order",
    //     data: this._data,
    //     componentName: "",
    //     url: purchaseOrderUrl,
    //     UI_TYPE: APP.UI_TYPE.NewTab
    // });

    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.PURCHASEORDER.New,
      menuTitle: "Create a Purchase Order",
      modalTitle: "Create New Purchase Order",
      saveButtonTitle: "Create Purchase Order",
      data: this._data,
      componentName: "NewPurchaseOrder",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Invoice
    let invoiceAction = {
      id: 2,
      eventType: this.EVENT_ACTION.INVOICE.New,
      menuTitle: "Create an Invoice",
      modalTitle: "Create New Invoice",
      componentName: "NewInvoice",
      saveButtonTitle: "Create Invoice",
      data: this._data,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    //Set Default Action
    this._defaultAction = invoiceAction;

    actions.push(invoiceAction);

    //Quote
    // let quoteUrl = this.earth.getNewQuoteNavigationUrl(this._data.intProjectID);
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.INVOICE.New,
      menuTitle: "Create a Quote",
      modalTitle: "Create New Quote",
      saveButtonTitle: "Create Quote",
      data: this._data,
      componentName: "NewQuote",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Old Work Order Screen
    let pageTitle = this.getTitle();
    let earthUrl = this.earth.getWorkOrderDetailsUrl(this._data.intProjectID, pageTitle);

    actions.push({
      id: 4,
      eventType: this.EVENT_ACTION.PROJECTS.ViewOld,
      menuTitle: "View Old Work Order",
      modalTitle: "Old Work Order Page",
      saveButtonTitle: "Old Work Order",
      data: this._data,
      componentName: "",
      url: earthUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    // actions.push({
    //   id: 5,
    //   eventType: this.EVENT_ACTION.IFRAME.AssignTechnician,
    //   menuTitle: "Assign Technicians",
    //   modalTitle: "Assign Technicians",
    //   saveButtonTitle: "Save",
    //   moduleId: this._moduleId,
    //   // url:
    //   componentName: this.EVENT_ACTION.IFRAME.AssignTechnician,
    //   data: this._data,
    //   UI_TYPE: APP.UI_TYPE.ModalIFrame,
    // });

    actions.push({
      id: 5,
      eventType: this.EVENT_ACTION.PROJECTS.AssignTech,
      menuTitle: "Assign Technicians",
      modalTitle: "Assign Technicians",
      saveButtonTitle: "Save",
      data: this._data,
      componentName: "AssignTechnicianModal",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    const newSchedulerUrl = this.earth.getNewSchedulerURL(this._data.intProjectID, this._data);
    actions.push({
      id: 9,
      eventType: this.EVENT_ACTION.PROJECTS.Scheduler,
      menuTitle: "Schedule Task",
      modalTitle: "Schedule Task",
      saveButtonTitle: "Schedule",
      // moduleId: this._moduleId,
      url: newSchedulerUrl,
      componentName: "",
      data: this._data,
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    actions.push({
      id: 6,
      eventType: this.EVENT_ACTION.IFRAME.RecurrenceSchedule,
      menuTitle: "Recurring Schedule",
      modalTitle: "Recurring Schedule",
      saveButtonTitle: "Save",
      moduleId: this._moduleId,
      // url:
      componentName: this.EVENT_ACTION.IFRAME.RecurrenceSchedule,
      data: this._data,
      UI_TYPE: APP.UI_TYPE.ModalIFrame,
    });

    actions.push({
      id: 8,
      eventType: this.EVENT_ACTION.PROJECTS.EditLocation,
      menuTitle: "Edit Location",
      modalTitle: "Edit Location",
      saveButtonTitle: "Update",
      data: this._data,
      componentName: "UpdateLocationProject",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    return actions;
  }

  //SECTION - Header
  getHeader(): ISidePanelHeader {
    let headerDataObj: ISidePanelHeader = new Object() as ISidePanelHeader;

    //Hide/Show Print Preview Button
    headerDataObj.isPreviewEnabled = true;

    //Hide/Show Email Button
    headerDataObj.isEmailEnabled = true;

    //Hide/Show Action Button
    headerDataObj.isActionButtonEnabled = true;

    headerDataObj.titleSection = new Object() as ILabelTitle;

    //Title Section (Left)
    headerDataObj.titleSection.headTitle = this.getTitle();

    headerDataObj.titleSection.status = this._data.status;

    //Sub Title
    headerDataObj.titleSection.subTitle = this.getSubTitle();

    //Action Button
    headerDataObj.actions = this.getDropDownMenu();

    headerDataObj.defaultAction = this._defaultAction;

    //Print Button
    headerDataObj.print = this._base.getPrintAction(this._data.intProjectID, MODULE.PROJECTS.ModuleId);

    //Email Button
    headerDataObj.email = this._base.getEmailAction(this._data, this._moduleId, this._actionId);

    return headerDataObj;
  }

  //SECTION - Action Bar
  getActionBar(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Edit Client
    let clientUrl = this.earth.getViewClientNavigationUrlJupiterPortal(this._data.intClientID, this._data.businessName);
    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.CLIENTS.View,
      menuTitle: "Edit Client",
      modalTitle: "Editing Client",
      saveButtonTitle: "Save Changes",
      data: this._data,
      componentName: "",
      url: clientUrl,
      icon: "fa fa-pencil",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Map
    let mapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this._data.location);

    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.CLIENTS.ExternalView,
      menuTitle: "Map",
      modalTitle: "",
      saveButtonTitle: "",
      data: this._data,
      componentName: "",
      url: mapUrl,
      icon: "fa fa-map-marker",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Dispatch
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.DISPATCH.New,
      menuTitle: "Dispatch",
      modalTitle: "Dispatch to Technician",
      saveButtonTitle: "Send Message",
      data: this._data,
      componentName: "NewDispatch",
      url: "",
      icon: "fa fa-truck",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Sales Quote Navigation
    //If has sales quotes
    if (this._data.intSalesQuotationID > 0) {
      let quoteUrl = this.earth.getViewQuoteDetailsUrlJupiterPortal(this._data.intSalesQuotationID);

      actions.push({
        id: 4,
        eventType: this.EVENT_ACTION.CLIENTS.View,
        menuTitle: "View Quote",
        modalTitle: "Editing Quote",
        saveButtonTitle: "Save Changes",
        data: this._data,
        componentName: "",
        url: quoteUrl,
        icon: "fa fa-file-text-o",
        UI_TYPE: APP.UI_TYPE.NewTab,
      });
    }

    //Dispatch
    actions.push({
      id: 5,
      eventType: this.EVENT_ACTION.DASHBOARD.Modal,
      menuTitle: "Stats",
      modalTitle: "Stats",
      saveButtonTitle: "",
      data: this._data,
      componentName: "ViewModalDashboard",
      url: "",
      icon: "fa fa-bar-chart",
      UI_TYPE: APP.UI_TYPE.Modal,
      actionId: this._actionId,
    });

    return actions;
  }

  //SECTION - Info Summary - Read only
  getInfoSectionDetails(): ISidePanelInfoSection {
    let infosection: ISidePanelInfoSection = {
      //Left Side of info section
      leftSection: {
        title: "Due Date:" + " " + this._data.displayDueDate, //Red title H3 font size
        labels: [
          {id: 0, title: "Start Date: ", labelValue: this._data.displayStartDate},
          {id: 1, title: "Est. Completion Date: ", labelValue: this._data.displayEndDate},
        ],
      },

      //Right Side of info section
      rightSection: {
        title: this._data.displayDueDateStatus, //Red title H3 font size
        labels: [
          //{ id: 0, title: "Total: ", labelValue: this.$options.filters.currency(project.totalAmonunt) },
          {id: 1, title: "Created: ", labelValue: this._data.displayCreatedDate},
          {id: 2, title: "Created By: ", labelValue: this._data.createdBy},
          // { id: 3, title: "Outstanding Balance: ", labelValue: Vue.$options.filters.currency(project.totalAmonunt) },
        ],
      },
    };

    return infosection;
  }

  //SECTION - Tabs
  getTabSection(): ISidePanelTabs[] {
    let tabs: ISidePanelTabs[] = [];

    //TAB - Details
    let detailsTab = {
      id: 1,
      title: "Details", //Name of the title
      actionButton: null,
      componentName: "ProjectDetails",
      componentPath: "@/components/projects/details",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(detailsTab);

    //TAB - Notes
    let notesTab = {
      id: 2,
      title: "Notes", //Name of the tab
      actionButton: null,
      componentName: "NotesUniversal",
      componentPath: "@/components/_universal/notes-universal",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.PROJECTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.NOTES.ActionId, //Action to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(notesTab);

    // //TAB - Custom
    // let customTab = {
    //     id: 3,
    //     title: "Custom", //Name of the tab
    //     actionButton: null,
    //     componentName: "TabIframeComponent",
    //     componentPath: "@/components/projects/custom",
    //     param: {   //Dynamically passs any additional paramaters required for api calls etc.
    //         id: this._data.id,
    //         parentActionId: 0,  //Parent Table the actionId table must be filtered on, by Id
    //         actionId: 0, //Action Id to determine what table list data to return
    //     }, //Action Id to determine what table list data to return
    //     url: this._sidepanel.iFrame.customUrl,
    //     data: this._data, //Any Data need passing to the modal box
    //     UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    // };

    // tabs.push(customTab);

    //TAB - Permits
    let permitsTab = {
      id: 4,
      title: "Permits", //Name of the Tab
      actionButton: null,
      componentName: "permits",
      componentPath: "@/components/projects/project-tab-permits",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(permitsTab);

    //TAB - Purchase Order
    let purchaseOrderTab = {
      id: 5,
      title: "Purchase Order", //Name of the Tab
      actionButton: null,
      componentName: "PurchaseOrder",
      componentPath: "@/components/projects/project-tab-purchase-order",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(purchaseOrderTab);

    // //TAB - Invoice
    let invoiceTab = {
      id: 6,
      title: "Invoices", //Name of the Tab
      actionButton: null,
      componentName: "Invoice",
      componentPath: "@/components/projects/project-tab-invoices",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(invoiceTab);

    // TAB - Equipment
    let equipmentTab = {
      id: 7,
      title: "Equipment", //Name of the title
      actionButton: this.getActionButton(MODULE.PROJECTS.Equipment, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.PROJECTS.Equipment, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.PROJECTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.PROJECTS.Equipment.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);

        return {
          uxInventory,
        };
      },
    };
    tabs.push(equipmentTab);

    //TAB - materials
    let servicesTab = {
      id: 8,
      title: "Services/Materials", //Name of the title
      actionButton: this.getActionButton(MODULE.MATERIALS, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.MATERIALS, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.PROJECTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.MATERIALS.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);

        return {
          uxInventory,
        };
      },
    };

    tabs.push(servicesTab);

    //TAB - Document/Photos
    // let documentsTab = {
    //   id: 9,
    //   title: "Documents/Photos", //Name of the Tab
    //   actionButton: {
    //     id: 1,
    //     eventType: this.EVENT_ACTION.DOCUMENTS_PHOTOS.New,
    //     menuTitle: "Add Document/Photos",
    //     modalTitle: "Add Document/Photos",
    //     saveButtonTitle: "Save",
    //     componentName: this.EVENT_ACTION.IFRAME.AddDocument,
    //     data: this._data,
    //     icon: "fa fa-pencil",
    //     UI_TYPE: APP.UI_TYPE.ModalIFrame,
    //   },
    //   componentName: "Documents",
    //   componentPath: "@/components/projects/project-tab-documents-photos",
    //   param: {
    //     //Dynamically passs any additional paramaters required for api calls etc.
    //     id: this._data.id,
    //     parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
    //     actionId: 0, //Action Id to determine what table list data to return
    //   }, //Action Id to determine what table list data to return
    //   data: this._data, //Any Data need passing to the modal box
    //   UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    // };

    let documentsTab = {
      id: 9,
      title: "Documents/Photos", //Name of the Tab
      actionButton: this.getActionButton(MODULE.DOCUMENTS, "new", APP.UI_TYPE.Modal),
      componentName: "UniversalDocuments",
      componentPath: "@/components/_universal/tab-documents",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.DASHBOARD.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.DOCUMENTS.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(documentsTab);

    // TAB - Tasks
    let tasksTab = {
      id: 10,
      title: "Tasks", //Name of the title
      actionButton: this.getActionButton(MODULE.TASKS, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.TASKS, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.PROJECTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.TASKS.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);

        return {
          uxInventory,
        };
      },
    };
    tabs.push(tasksTab);

    // TAB - Time Tracking
    let timeTrackingTab = {
      id: 11,
      title: "Time Tracking", //Name of the title
      actionButton: this.getActionButton(MODULE.TIMETRACKING, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.TIMETRACKING, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.PROJECTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.TIMETRACKING.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);

        return {
          uxInventory,
        };
      },
    };

    tabs.push(timeTrackingTab);

    // TAB - History;
    let historyTab = {
      id: 12,
      title: "History", //Name of the Tab
      actionButton: null,
      componentName: "Activity",
      componentPath: "@/components/activity/activity-history",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(historyTab);

    return tabs;
  }

  //Info Summery
  async getInfoDetails(id): Promise<IInfo> {
    let info = new Object() as IInfo;
    try {
      //Not implemented for now
      info.isEnabled = false;

      let filter = {
        actionId: this._actionId,
        id: id,
      };

      let results = await this.search.getResults("global/details/info", filter);

      info.data = results.resources;

      info.isDetailsView = true;
    } catch (error) {}
    return info;
  }

  getActionButton(module, actionType, UI_MODAL_TYPE) {
    let title = "";
    let componentName = "";
    let eventType = null;

    //Set Location Tab Details
    if (module === MODULE.MATERIALS) {
      eventType = this.EVENT_ACTION.SERVICES_MATERIALS;

      if (actionType === "new") {
        title = "Add Service/Materials";
        componentName = "NewMaterial";
      } else if (actionType === "edit") {
        title = "Edit Service/Materials";
        componentName = "NewMaterial";
      }
    }

    if (module === MODULE.DOCUMENTS) {
      eventType = this.EVENT_ACTION.DOCUMENTS_PHOTOS;

      if (actionType === "new") {
        title = "Add Document/Photos";
        componentName = this.EVENT_ACTION.DOCUMENTS_PHOTOS.New;
      }
    }

    if (module === MODULE.PROJECTS.Equipment) {
      eventType = this.EVENT_ACTION.EQUIPMENT;

      if (actionType === "new") {
        title = "Add Equipment";
        componentName = "NewProjectEquipment";
      } else if (actionType === "edit") {
        title = "Edit Equipment";
        componentName = "NewProjectEquipment";
      }
    }

    if (module === MODULE.TASKS) {
      eventType = this.EVENT_ACTION.TASK;

      if (actionType === "new") {
        title = "Assign Task";
        componentName = "NewProjectTask";
      } else if (actionType === "edit") {
        title = "Edit Task";
        componentName = "NewProjectTask";
      }
    }

    if (module === MODULE.TIMETRACKING) {
      eventType = this.EVENT_ACTION.TASK;

      if (actionType === "new") {
        title = "Log Time";
        componentName = "NewProjectTimeTracking";
      } else if (actionType === "edit") {
        title = "Edit Time";
        componentName = "NewProjectTimeTracking";
      }
    }

    if (actionType === "new") {
      return {
        id: 1,
        actionId: module.ActionId,
        parentActionId: this._actionId,
        eventType: eventType.New,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
        moduleId: this._moduleId,
      };
    } else if (actionType === "edit") {
      return {
        id: 2,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: true,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    }

    return null;
  }

  //MAIN - ENTRY
  async main(data): Promise<IUXSidePanel> {
    this._data = data;
    this._data.typeId = MODULE.NOTES.PROJECT;
    //Set Id for generic implementation
    this._data.id = this._data.intProjectID;

    //Decide if Side panel should show Iframe or load component view
    this._sidepanel.isSidePanelFrame = false;
    this._sidepanel.actionId = this._actionId;

    //Specify the name of the page being loaded
    this._sidepanel.pageName = APP.PAGE.Projects.Details;

    //Header
    this._sidepanel.headerDataObj = this.getHeader();

    //Action Bar
    this._sidepanel.actionBarDataObj = this.getActionBar();

    //Info Section
    this._sidepanel.infoSectionDataObj = this.getInfoSectionDetails();

    //Info Stats
    this._sidepanel.info = await this.getInfoDetails(data.id);

    //External Links
    this._sidepanel.iFrame = this._base.getIFrameDetails(this._data, this._data.intProjectID);

    //Tabs
    this._sidepanel.tabSectionDataObj = this.getTabSection();

    this._sidepanel.details = data;

    return this._sidepanel;
  }
}

export default ProjectController;
